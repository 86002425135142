import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import { Spinner } from "react-bootstrap";
import LoginPage from "./components/LoginPage";
import NavbarComponent from "./components/NavbarComponent";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import Subscription from "./components/SubscriptionPage/Subscription";
import SignUpLab from "./components/signupClient/SignUpLab";
import SignUpLIS from "./components/signupClient/SignUpLIS";
import SignUpAdmin from "./components/signupClient/SignUpAdmin";
import MaintenancePage from "../src/components/maintenance";
import ClientsTableView from "./components/SubscriptionPage/tableView";
import VizAppDashboard from "./components/DashboardVizapp";
import Analytics from "./components/AnalyticsSection/Analytics";
import SupportSection from "./components/HelpSection/SupportSection";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./theme";
// import ManualReports from "./components/DemoPortal/ManualReports";
import DragDropCSV from "./components/DemoPortal/DragDropCSV";
import HomePage from "./components/DemoPortal/HomePage";
import ItemSelectionScreen from "./components/DemoPortal/ItemSelectionScreen";
import ProfileTests from "./components/DemoPortal/ProfileTests";
import STIReport from "./components/DemoPortal/STI_REPORT";
import Archives from "./components/DemoPortal/archives";
import Referral from "./components/ReferralSection/Referral";

const App = () => {
  // RECORD DATE
  // var datetime =  new Date().toLocaleString().replace(',','')
  // let onlyDate = datetime.split(" ")[0]
  // let onlyTime = datetime.split(" ")[1]

  // console.log("date and time: ", onlyDate, ",",onlyTime)

  // useEffect(() => {
  //   window.addEventListener("beforeunload", () => localStorage.removeItem('loginDetails'));
  // }, []);

  // DETECT CLOSING AND REFRESHING OF WEBPAGE
  // useEffect(() => {
  //   return () => {

  //      window.addEventListener("beforeunload", function(e) {

  //     let confirmationMessage = "o/";

  //     (e || window.event).returnValue = confirmationMessage; //Gecko + IE

  //     console.log("logout !");
  //     localStorage.setItem("myLoginDetails", JSON.stringify(""))
  //     localStorage.removeItem("loginDetails")
  //     localStorage.removeItem("homepageData")

  //     return confirmationMessage; //Webkit, Safari, Chrome

  //   });
  //  }

  // });

  // react alert config
  const options = {
    timeout: 5000,
    // position: positions.BOTTOM_CENTER
  };

  const [loadingStatus, setLoadingStatus] = useState(true);

  const cacheImages = async (imagesToLoad) => {
    const promises = await imagesToLoad.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();

        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setLoadingStatus(false);
  };

  useEffect(() => {
    // loading all the images first
    // const allImages = collectionOfAllLinks

    const allImages = [
      "https://niroggyan.s3.ap-south-1.amazonaws.com/React-Billing-Dashboard-files/WelcomeScreen+Files/welcomeScreenSvg.svg",
    ];

    //console.log("all Links\n", allImages)

    cacheImages(allImages);
  }, []);

  const loginData = JSON.parse(localStorage.getItem("loginDetails"));
  console.log("loginDataApp", loginData);

  const ProtectedRoutes = () => {
    const loginData = JSON.parse(localStorage.getItem("loginDetails"));
    return loginData ? <Outlet /> : <Navigate to="/login" />;
  };

  return (
    <>
      {loadingStatus ? (
        <Spinner
          style={{ position: "absolute", top: "50%", left: "50%" }}
          id="loadingSpinnerCss"
          animation="border"
          role="status"
        />
      ) : (
        <>
          {/* <NavbarComponent /> */}
          <ThemeProvider theme={theme}>
            <Provider template={AlertTemplate} {...options}>
              <div
                // className="container-fluid"
                style={
                  {
                    // padding: "3% 5%",
                  }
                }
              >
                <Router>
                  <Routes>
                    <Route element={<ProtectedRoutes />}>
                      <Route
                        exact
                        path="/"
                        element={
                          loginData ? <VizAppDashboard /> : <LoginPage />
                        }
                      />
                      {/* <Route
                        exact
                        path="/subscription"
                        element={<Subscription />}
                      /> */}
                      <Route
                        exact
                        path="/subscription"
                        element={<VizAppDashboard />}
                      />
                      <Route
                        exact
                        path="/new-client/lab"
                        element={<SignUpLab />}
                      />
                      <Route
                        exact
                        path="/new-client/lis"
                        element={<SignUpLIS />}
                      />
                      <Route
                        exact
                        path="/new-client/admin"
                        element={<SignUpAdmin />}
                      />
                      <Route
                        exact
                        path="/maintenance"
                        element={<MaintenancePage />}
                      />
                      <Route
                        exact
                        path="/client-Data"
                        element={<ClientsTableView />}
                      />
                      <Route exact path="/analytics" element={<Analytics />} />
                      <Route
                        exact
                        path="/support"
                        element={<SupportSection />}
                      />
                      <Route exact path="/refer" element={<Referral />} />
                      <Route
                        exact
                        path="/manualReports"
                        element={<DragDropCSV />}
                      />
                      <Route
                        path="/manualReports/Manual-Entry-Home-Page"
                        element={<HomePage />}
                        exact
                      />
                      <Route
                        path="/manualReports/itemSelection"
                        element={<ItemSelectionScreen />}
                        exact
                      />
                      <Route
                        path="/manualReports/profileTests"
                        element={<ProfileTests />}
                        exact
                      />

                      <Route
                        path="/manualReports/STI_Report"
                        element={<STIReport />}
                        exact
                      />
                      <Route
                        path="/manualReports/archives"
                        element={<Archives />}
                        exact
                      />
                    </Route>
                    {/* <Route path="*" element={<NotFound/>}/> */}

                    <Route exact path="/login" element={<LoginPage />} />
                  </Routes>
                </Router>
              </div>
            </Provider>
          </ThemeProvider>
        </>
      )}
    </>
  );
};

export default App;
