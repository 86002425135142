import { Label } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useEffect, useMemo, useState } from "react";
import Legend from "./Legend";
import LoadingView from "../../common/LoadingView";
import ErrorView from "../../common/ErrorView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CustomBox, CustomShadowBox } from "../../common/BoxAbstraction";
import EmptyView from "../../common/EmptyView";
import CollapsableHeader from "../../common/CollapsableHeader";
import { getCurrentWeekDates } from "../utils";

const AbnormalByAgeGender = () => {
  const today = new Date().getDate();
  const [selectedMetric, setSelectedMetric] = useState("currentWeek");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [maxcalenderDate, SetMaxCalenderDate] = useState("");
  const [abnormalParameterData, setAbnormalParameterData] = useState("");
  const [abnormalProfileData, setAbnormalProfileData] = useState("");
  const [profileError, setProfileError] = useState("");
  const [parameterError, setParameterError] = useState("");
  const [profileLoader, setProfileLoader] = useState(false);
  const [parameterLoader, setParameterLoader] = useState(false);
  const [open, setOpen] = useState(false);

  const [gender, setGender] = useState("Male");
  const [ageRange, setAgeRange] = useState("0-10");
  const [showEmptyView, setShowEmptyView] = useState(true);

  const handleClose = () => setOpen(false);
  const handleShow = () => setOpen(true);
  const loginData = JSON.parse(localStorage.getItem("credentials"));

  const ageRanges = [
    "0-10",
    "10-20",
    "20-30",
    "30-40",
    "40-50",
    "50-60",
    "60-70",
    "70-80",
    "80-90",
    "90-100",
  ];

  const handleDataCall = () => {
    setShowEmptyView(false);
    fetchProfileData();
    if (!loginData.disableParameterData) {
      fetchParameterData();
    }
  };

  const fetchProfileData = async () => {
    setProfileLoader(true);
    setProfileError("");

    const apiUrl = `https://api.niroggyan.com/analytics/smartreport/demographics/counts?query_on=age_gender&start=${startDate}&end=${endDate}&filter_by=abnormal_profile&gender=${gender}&age_start=${parseInt(
      ageRange.split("-")[0]
    )}&age_end=${parseInt(ageRange.split("-")[1])}`;

    const idToken = localStorage.getItem("idTokenBilling");

    let options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: idToken,
      },
    };
    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("ageWiseProfileData", data);
      if (data?.data.length) {
        setAbnormalProfileData(data.data);
      } else {
        setProfileError("No data found");
      }
    } catch (e) {
      console.log("Error", e);
      setProfileError("Failed to fetch profile data. Please try again.");
    } finally {
      setProfileLoader(false);
    }
  };

  const fetchParameterData = async () => {
    setParameterLoader(true);
    setParameterError("");

    const apiUrl = `https://api.niroggyan.com/analytics/smartreport/demographics/counts?query_on=age_gender&start=${startDate}&end=${endDate}&filter_by=abnormal_parameter&gender=${gender}&age_start=${parseInt(
      ageRange.split("-")[0]
    )}&age_end=${parseInt(ageRange.split("-")[1])}`;

    const idToken = localStorage.getItem("idTokenBilling");

    let options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: idToken,
      },
    };
    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("ageWiseProfileData", data);
      if (data?.data.length) {
        setAbnormalParameterData(data.data);
      } else {
        setParameterError("No data found");
      }
    } catch (e) {
      console.log("Error", e);
      setParameterError("Failed to fetch biomarker data. Please try again.");
    } finally {
      setParameterLoader(false);
    }
  };

  useEffect(() => {
    // if (today < 5) {
    //   setStartDate(getPreviousMonthStartEndDates().startDate);
    //   setEndDate(getPreviousMonthStartEndDates().endDate);
    //   setSelectedMetric("previousMonth");
    // } else {
    //   setStartDate(getCurrentMonthStartEndDates().startDate);
    //   setEndDate(getCurrentMonthStartEndDates().endDate);
    //   setSelectedMetric("currentMonth");
    // }
    setStartDate(getCurrentWeekDates().startDate);
    setEndDate(getCurrentWeekDates().endDate);
  }, []);

  const formatFromDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() >= 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 00:00:00.000`;
    console.log(requiredTime);
    return requiredTime;
  };

  const formatToDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() >= 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 23:59:59.999`;
    console.log(requiredTime);
    return requiredTime;
  };

  const getCurrentMonthStartEndDates = () => {
    let todaysDate = new Date();
    let startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      1,
      0,
      0,
      0,
      0
    );

    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(todaysDate),
    };
  };

  const getPreviousMonthStartEndDates = () => {
    let todaysDate = new Date();
    const startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth() - 1,
      1,
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      0,
      23,
      59,
      59,
      0
    );
    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(endDate),
    };
  };

  const setMaxDateCalender = (date) => {
    const dateFormat = new Date(date);
    const maxDate = new Date(dateFormat.setDate(dateFormat.getDate() + 31));
    const month =
      maxDate.getMonth() < 9
        ? `0${parseInt(maxDate.getMonth()) + 1}`
        : parseInt(maxDate.getMonth()) + 1;
    const day =
      maxDate.getDate() < 10 ? `0${maxDate.getDate()}` : maxDate.getDate();
    const formattedDate = `${maxDate.getFullYear()}-${month}-${day}`;

    SetMaxCalenderDate(formattedDate);
  };

  const customDateModal = () => {
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select Custom Date</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Box>
              <Typography mb={1}>From Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={startDate}
                onChange={(e) => {
                  setStartDate(formatFromDate(e.target.value));
                  setMaxDateCalender(e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography mb={1}>To Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={endDate}
                max={maxcalenderDate}
                onChange={(e) => setEndDate(formatToDate(e.target.value))}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button variant="contained" size="small" onClick={handleClose}>
            Save Changes
          </Button>
          <Button variant="contained" size="small" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleSelection = (e) => {
    setSelectedMetric(e.target.value);
    switch (e.target.value) {
      case "currentWeek":
        setStartDate(getCurrentWeekDates().startDate);
        setEndDate(getCurrentWeekDates().endDate);
        break;
      case "currentMonth":
        setStartDate(getCurrentMonthStartEndDates().startDate);
        setEndDate(getCurrentMonthStartEndDates().endDate);
        break;
      case "previousMonth":
        setStartDate(getPreviousMonthStartEndDates().startDate);
        setEndDate(getPreviousMonthStartEndDates().endDate);
        break;
      case "customMonth":
        setOpen(true);
        break;
    }
  };

  const handleAgeChange = (e) => {
    setAgeRange(e.target.value);
  };

  const ageDropdown = (title, value, handlerFunction) => {
    return (
      <FormControl size="small" variant="filled">
        <InputLabel id="demo-select-small-label">{title}</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={value}
          label="Date"
          onChange={(e) => handlerFunction(e)}
          sx={{ minWidth: "120px" }}
        >
          {ageRanges.map((each, idx) => (
            <MenuItem key={idx} value={each}>
              {each}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <Box>
      <CustomBox>
        <Typography variant="h2" mb={3}>
          Abnormal Profile & Biomarkers by Demographics
        </Typography>
        <CustomShadowBox mb={2}>
          <Stack direction="row" gap={3}>
            <FormControl size="small" variant="filled">
              <InputLabel id="demo-select-small-label">Gender</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={gender}
                label="Date"
                onChange={(e) => setGender(e.target.value)}
                sx={{ minWidth: "100px" }}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
            </FormControl>
            {ageDropdown("Age Range", ageRange, handleAgeChange)}

            <FormControl size="small" variant="filled">
              <InputLabel id="demo-select-small-label">Time Period</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedMetric}
                label="Date"
                onChange={handleSelection}
              >
                <MenuItem value="currentWeek">Current Week</MenuItem>
                <MenuItem value="currentMonth">Current Month</MenuItem>
                <MenuItem value="previousMonth">Previous Month</MenuItem>
                <MenuItem value="customMonth">Custom Month</MenuItem>
              </Select>
            </FormControl>
            <Button
              onClick={handleDataCall}
              disabled={parameterLoader || profileLoader}
            >
              Get Details
            </Button>
            {customDateModal()}
          </Stack>
        </CustomShadowBox>
        {!showEmptyView ? (
          <Stack direction={"row"} gap={2}>
            <Box
              sx={{ width: loginData?.disableParameterData ? "100%" : "50%" }}
            >
              {profileLoader ? (
                <LoadingView />
              ) : profileError ? (
                <ErrorView error={profileError} />
              ) : abnormalProfileData?.length ? (
                <Stack>
                  <TableContainer
                    component={Paper}
                    sx={{ maxHeight: "350px", overflowY: "auto" }}
                  >
                    <Table
                      sx={{ minWidth: "100%" }}
                      aria-label="simple table"
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "600", width: "10%" }}>
                            SN
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: "600", width: "50%" }}
                            align="left"
                          >
                            Profile Name
                          </TableCell>

                          <TableCell align="center" sx={{ fontWeight: "600" }}>
                            Abnormality Count
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {abnormalProfileData.map((each, idx) => (
                          <TableRow
                            key={idx}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="left">{idx + 1}</TableCell>
                            <TableCell align="left">{each.test_name}</TableCell>
                            <TableCell align="center">
                              {each.abnormal_count?.toLocaleString("en-IN")}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              ) : (
                ""
              )}
            </Box>
            <Box
              sx={{
                width: "50%",
                display: loginData?.disableParameterData ? "none" : "",
              }}
            >
              {parameterLoader ? (
                <LoadingView />
              ) : parameterError ? (
                <ErrorView error={parameterError} />
              ) : abnormalParameterData?.length ? (
                <Stack flexGrow={1}>
                  <TableContainer
                    component={Paper}
                    sx={{ maxHeight: "350px", overflowY: "auto" }}
                  >
                    <Table
                      sx={{ minWidth: "100%" }}
                      aria-label="simple table"
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "600", width: "10%" }}>
                            SN
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: "600", width: "50%" }}
                            align="left"
                          >
                            Biomarker Name
                          </TableCell>

                          <TableCell align="center" sx={{ fontWeight: "600" }}>
                            Abnormality Count
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {abnormalParameterData.map((each, idx) => (
                          <TableRow
                            key={idx}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="left">{idx + 1}</TableCell>
                            <TableCell align="left">
                              {each.parameter_name}
                            </TableCell>
                            <TableCell align="center">
                              {each.abnormal_count?.toLocaleString("en-IN")}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              ) : (
                ""
              )}
            </Box>
          </Stack>
        ) : (
          <EmptyView message="Please choose gender & age range" />
        )}
      </CustomBox>
    </Box>
  );
};

export default AbnormalByAgeGender;
