import {
  Box,
  Paper,
  Stack,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import NavbarComponent from "../NavbarComponent";
import DemographicDetails from "./DemographicDetails";
import ParameterDetails from "./ParameterDetails";
import ProfileDetails from "./ProfileDetails";
import AbnormalMetrics from "./AbnormalMetrics";
import PackageWiseAnalysis from "./PackageWiseAnalysis";
import AgeGenderAnalysis from "./AgeGenderAnalysis";
import AbnormalParameters from "./AbnormalParameters";
import ParameterCorrelation from "./ParameterCorrelation";
import Feedback from "./Feedback";
import { CustomBox, CustomGraphBox } from "../../common/BoxAbstraction";
import AbnormalByAgeGender from "./AbnormalByAgeGender";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReportGenerationTAT from "./ReportGenerationTAT.js";
import CollapsableHeader from "../../common/CollapsableHeader";
import PageTitle from "../../common/PageTitle.js";
import { content } from "../utils.js";

const Analytics = () => {
  const loginData = JSON.parse(localStorage.getItem("loginDetails"));
  return (
    <Stack direction="row" gap={0}>
      <NavbarComponent />

      {loginData && loginData.showAnalytics ? (
        <Stack gap={3} flexGrow={1} sx={{ padding: "0px 64px 40px" }}>
          {/* <Typography
            variant="h1"
            sx={{
              position: "sticky",
              top: "0",
              zIndex: 100,
              background: "#E9F5FE",
              padding: "64px 16px 18px",
              margin: "0px -16px",
            }}
          >
            Analytics
          </Typography> */}
          <PageTitle title="Analytics" />

          <DemographicDetails />

          <AgeGenderAnalysis />
          {/* <AbnormalByAgeGender /> */}

          <Box>
            <Accordion component="Box">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {/* <Typography variant="h2" mb={2}>
                Top Abnormal Profile & Biomarker Details
              </Typography> */}
                <CollapsableHeader
                  title={content.profileParameterTables.title}
                  subheading={content.profileParameterTables.subHeading}
                  tooltipText={content.profileParameterTables.tooltipText}
                />
              </AccordionSummary>
              <AccordionDetails>
                <CustomBox sx={{ padding: "40px 20px" }}>
                  <Stack direction="row" gap={1} flexWrap="wrap">
                    <CustomGraphBox
                      sx={{
                        padding: "20px 12px",
                        width: { lg: "40%", md: "100%" },
                        flexGrow: "1",
                      }}
                    >
                      <ProfileDetails />
                      <CustomGraphBox mt={4} sx={{ padding: "16px" }}>
                        <AbnormalMetrics isProfile="true" />
                      </CustomGraphBox>
                    </CustomGraphBox>
                    <CustomGraphBox
                      sx={{
                        padding: "20px 12px",
                        width: { lg: "40%", md: "100%" },
                        flexGrow: "1",
                      }}
                    >
                      <ParameterDetails />
                      <CustomGraphBox mt={4} sx={{ padding: "16px" }}>
                        <AbnormalMetrics />
                      </CustomGraphBox>
                    </CustomGraphBox>
                  </Stack>
                </CustomBox>
              </AccordionDetails>
            </Accordion>
          </Box>

          <PackageWiseAnalysis />

          <AbnormalParameters />

          {/* <AbnormalByAgeGender /> */}

          {/* <CustomBox>
          <ParameterCorrelation />
        </CustomBox> */}
          {/* <CustomBox>
          <Feedback />
        </CustomBox> */}

          <ReportGenerationTAT />
        </Stack>
      ) : (
        <Stack gap={0} flexGrow={1} sx={{ padding: "0px 64px 40px" }}>
          {/* <Typography variant="h1">Analytics</Typography> */}
          <PageTitle title="Analytics" />
          <CustomBox
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>Currently this feature is locked for you</Typography>
          </CustomBox>
        </Stack>
      )}
    </Stack>
  );
};

export default Analytics;
