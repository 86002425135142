import { Label } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  colors,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";

import Chart from "react-apexcharts";

import { useEffect, useState } from "react";
import Legend from "./Legend";
import { CustomBox, CustomCountBox } from "../../common/BoxAbstraction";
import LoadingView from "../../common/LoadingView";
import ErrorView from "../../common/ErrorView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CollapsableHeader from "../../common/CollapsableHeader";
import { content, getCurrentWeekDates } from "../utils";

const DemographicDetails = () => {
  const today = new Date().getDate();
  const [selectedMetric, setSelectedMetric] = useState("currentWeek");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [maxcalenderDate, SetMaxCalenderDate] = useState("");
  const [demographicData, setDemographicData] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleShow = () => setOpen(true);

  const legendColors = ["#68A1AC", "#1EA8C7", "#22CB8E", "#0C847A"];

  const fetchDemographicData = async () => {
    setLoader(true);
    const apiUrl = `https://api.niroggyan.com/analytics/smartreport/demographics/counts?query_on=age_gender&start=${startDate}&end=${endDate}&filter_by=age`;

    const idToken = localStorage.getItem("idTokenBilling");

    let options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: idToken,
      },
    };
    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("demography", data);
      if (data.isSuccess) {
        if (data?.data?.length === 0) {
          setError("No data available for the chosen dates.");
        } else {
          setError("");
          setDemographicData(data.data);
          if (selectedMetric === "currentWeek") {
            localStorage.setItem("demographicsData", JSON.stringify(data.data));
          }
        }
      } else {
        setError("Failed to fetch data. Please try again.");
      }
    } catch (e) {
      console.log("Error", e);
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    // if (today < 5) {
    //   setStartDate(getPreviousMonthStartEndDates().startDate);
    //   setEndDate(getPreviousMonthStartEndDates().endDate);
    //   setSelectedMetric("previousMonth");
    // } else {
    //   setStartDate(getCurrentMonthStartEndDates().startDate);
    //   setEndDate(getCurrentMonthStartEndDates().endDate);
    //   setSelectedMetric("currentMonth");
    // }
    setStartDate(getCurrentWeekDates().startDate);
    setEndDate(getCurrentWeekDates().endDate);
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      const storedDemographicData = JSON.parse(
        localStorage.getItem("demographicsData")
      );
      if (selectedMetric === "currentWeek" && storedDemographicData) {
        setDemographicData(storedDemographicData);
      } else {
        fetchDemographicData();
      }
    }
  }, [startDate, endDate]);

  const formatFromDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() >= 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 00:00:00.000`;
    console.log(requiredTime);
    return requiredTime;
  };

  const formatToDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() >= 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 23:59:59.999`;
    console.log(requiredTime);
    return requiredTime;
  };

  const getCurrentMonthStartEndDates = () => {
    let todaysDate = new Date();
    let startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      1,
      0,
      0,
      0,
      0
    );

    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(todaysDate),
    };
  };

  const getPreviousMonthStartEndDates = () => {
    let todaysDate = new Date();
    const startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth() - 1,
      1,
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      0,
      23,
      59,
      59,
      0
    );
    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(endDate),
    };
  };

  const setMaxDateCalender = (date) => {
    const dateFormat = new Date(date);
    const maxDate = new Date(dateFormat.setDate(dateFormat.getDate() + 31));
    const month =
      maxDate.getMonth() < 9
        ? `0${parseInt(maxDate.getMonth()) + 1}`
        : parseInt(maxDate.getMonth()) + 1;
    const day =
      maxDate.getDate() < 10 ? `0${maxDate.getDate()}` : maxDate.getDate();
    const formattedDate = `${maxDate.getFullYear()}-${month}-${day}`;

    SetMaxCalenderDate(formattedDate);
  };

  const customDateModal = () => {
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select Custom Date</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Box>
              <Typography mb={1}>From Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={startDate}
                onChange={(e) => {
                  setStartDate(formatFromDate(e.target.value));
                  setMaxDateCalender(e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography mb={1}>To Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={endDate}
                max={maxcalenderDate}
                onChange={(e) => setEndDate(formatToDate(e.target.value))}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button variant="contained" size="small" onClick={handleClose}>
            Save Changes
          </Button>
          <Button variant="contained" size="small" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleSelection = (e) => {
    setSelectedMetric(e.target.value);
    switch (e.target.value) {
      case "currentWeek":
        setStartDate(getCurrentWeekDates().startDate);
        setEndDate(getCurrentWeekDates().endDate);
        break;
      case "currentMonth":
        setStartDate(getCurrentMonthStartEndDates().startDate);
        setEndDate(getCurrentMonthStartEndDates().endDate);
        break;
      case "previousMonth":
        setStartDate(getPreviousMonthStartEndDates().startDate);
        setEndDate(getPreviousMonthStartEndDates().endDate);
        break;
      case "customMonth":
        setOpen(true);
        break;
    }
  };

  const filteredDemographicData =
    demographicData &&
    demographicData.filter((each) => each.gender !== "Unknown");

  console.log("filteredDemographicData", filteredDemographicData);

  const removeGender =
    filteredDemographicData?.length &&
    filteredDemographicData.map((each) => {
      return {
        "Under 18": parseInt(each["Under 18"]),
        "18-35": parseInt(each["18-35"]),
        "36-50": parseInt(each["36-50"]),
        "Over 50": parseInt(each["Over 50"]),
      };
    });

  const labels = removeGender?.length && Object.values(removeGender[0]);

  console.log("removeGender", removeGender);

  const getGraphdata = () => {
    const chartData = [];
    const dataObject = {};
    const ageFilter = filteredDemographicData.map((each) => {
      return {
        "Under 18": each["Under 18"],
        "18-35": each["18-35"],
        "36-50": each["36-50"],
        "Over 50": each["Over 50"],
      };
    });
    console.log("filteredDemographicData", ageFilter);
    if (demographicData && demographicData.length) {
      ageFilter.forEach((each) => {
        for (let [key, value] of Object.entries(each)) {
          if (key in dataObject) {
            dataObject[key].push(value);
          } else {
            dataObject[key] = [value];
          }
        }
      });
    }
    console.log("dataObject", dataObject);
    for (let [key, value] of Object.entries(dataObject)) {
      chartData.push({
        name: key,
        data: value,
      });
    }

    console.log("chartData", chartData);
    return chartData;
  };

  let dataToRender = {
    options: {
      dataLabels: {
        enabled: true,
        position: "top",
        offsetY: 20,
        // dropShadow: {
        //   enabled: true,
        //   left: 2,
        //   top: 2,
        //   opacity: 0.5,
        // },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "60%",
        },
      },
      chart: {
        id: "Demographic",
        fontFamily: "IBM Plex Sans",
      },
      xaxis: {
        categories: ["Female", "Male"],
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: 500,
          },
        },
        title: {
          text: "Age in years",
          margin: 10,
          style: {
            fontSize: "18px",
            fontWeight: 700,
          },
        },
      },
      yaxis: {
        title: {
          text: "Report Count",
          margin: 10,
          style: {
            fontSize: "18px",
            fontWeight: 700,
          },
        },
      },
      legend: {
        show: false,
      },
      colors: ["#68A1AC", "#1EA8C7", "#22CB8E", "#0C847A"],
    },
    series: demographicData && getGraphdata(),
  };

  const totalFemale =
    removeGender?.length &&
    Object.values(removeGender[0]).reduce((a, b) => a + b);
  const totalMale =
    removeGender?.length === 2
      ? Object.values(removeGender[1]).reduce((a, b) => a + b)
      : 0;

  const RenderPieChart = ({ isFemale }) => {
    const pieChartData = {
      options: {
        chart: {
          // width: 380,
          type: "pie",
          fontFamily: "IBM Plex Sans",
        },
        legend: {
          show: false,
          position: "bottom",
        },
        labels:
          removeGender.length &&
          (isFemale
            ? Object.keys(removeGender[0])
            : Object.keys(removeGender[1])),
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -10, // Set to 0 to align labels with the center of the slices
              minAngleToShowLabel: 2, // Set a minimum angle to show labels
              offsetY: 0, // Adjust vertical positioning of labels
              offsetX: 0, // Adjust horizontal positioning of labels
            },
          },
        },
        colors: ["#68A1AC", "#1EA8C7", "#22CB8E", "#0C847A"],
      },
      series:
        removeGender.length &&
        (isFemale
          ? Object.values(removeGender[0])
          : Object.values(removeGender[1])),
    };

    return (
      <Stack alignItems="center" gap={2}>
        <Typography variant="body10">{isFemale ? "Female" : "Male"}</Typography>

        <Chart
          options={pieChartData.options}
          series={pieChartData.series}
          type="pie"
          width="100%"
        />
        <CustomCountBox>
          <Typography variant="body9">
            Count:{" "}
            {isFemale
              ? totalFemale?.toLocaleString("en-IN")
              : totalMale?.toLocaleString("en-IN")}
          </Typography>
        </CustomCountBox>
      </Stack>
    );
  };

  return (
    <Box>
      <Accordion component="Box" defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {/* <Typography variant="h2">Patient Demographics</Typography> */}
          <CollapsableHeader
            title={content.demographicsOverview.title}
            subheading={content.demographicsOverview.subHeading}
            tooltipText={content.demographicsOverview.tooltipText}
          />
        </AccordionSummary>
        <AccordionDetails>
          <CustomBox>
            <Stack
              p={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                borderRadius: "12px",
                boxShadow: "0px 1px 50px 0px rgba(0, 0, 0, 0.08)",
              }}
            >
              {demographicData?.length ? (
                <CustomCountBox>
                  <Typography variant="body9">
                    Total Count:{" "}
                    {(totalMale + totalFemale)?.toLocaleString("en-IN")}
                  </Typography>
                </CustomCountBox>
              ) : (
                ""
              )}
              {demographicData?.length ? (
                <Box>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    Age in years
                  </Typography>
                  <Stack
                    gap={2}
                    direction="row"
                    justifyContent="center"
                    mb={2}
                    mt={2}
                    flexWrap="wrap"
                  >
                    {legendColors.map((each, idx) => (
                      <Legend
                        key={idx}
                        color={each}
                        name={Object.keys(removeGender[0])[idx]}
                      />
                    ))}
                  </Stack>
                </Box>
              ) : (
                ""
              )}
              <FormControl
                size="small"
                variant="filled"
                // sx={{ alignSelf: "flex-end" }}
              >
                <InputLabel id="demo-select-small-label">
                  Time Period
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={selectedMetric}
                  label="Date"
                  onChange={handleSelection}
                >
                  <MenuItem value="currentWeek">Current Week</MenuItem>
                  <MenuItem value="currentMonth">Current Month</MenuItem>
                  <MenuItem value="previousMonth">Previous Month</MenuItem>
                  <MenuItem value="customMonth">Custom Month</MenuItem>
                </Select>
              </FormControl>
              {customDateModal()}
            </Stack>

            <Stack direction="column" justifyContent="space-between" gap={2}>
              <Stack>
                {loader ? (
                  <LoadingView />
                ) : error ? (
                  <ErrorView error={error} />
                ) : (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="wrap"
                    // sx={{ width: "100%" }}
                    p={3}
                  >
                    <Stack sx={{ width: "40%" }} flexGrow={1}>
                      <RenderPieChart isFemale="true" />
                    </Stack>
                    {/* {demographicData?.length ? (
                  <Stack gap={2} justifyContent="flex-end" mb={2} mt={2}>
                    {legendColors.map((each, idx) => (
                      <Legend
                        key={idx}
                        color={each}
                        name={Object.keys(removeGender[0])[idx]}
                      />
                    ))}
                  </Stack>
                ) : (
                  ""
                )} */}
                    <Stack sx={{ width: "40%" }} flexGrow={1}>
                      <RenderPieChart />
                    </Stack>
                  </Stack>
                )}
              </Stack>
              <Stack
                // alignSelf="stretch"
                px={4}
                py={3}
                sx={{
                  borderRadius: "8px",
                  border: "1px solid #E2E2E2",
                  boxShadow: "0px 1px 50px 0px rgba(0,0,0,0.08)",
                }}
              >
                {loader ? (
                  <LoadingView />
                ) : error ? (
                  <ErrorView error={error} />
                ) : (
                  <Chart
                    options={dataToRender.options}
                    series={dataToRender.series}
                    type="bar"
                    // width={"100%"}
                    height={320}
                  />
                )}
              </Stack>
            </Stack>
          </CustomBox>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default DemographicDetails;
