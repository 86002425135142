import { Label } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useEffect, useMemo, useState } from "react";
import Legend from "./Legend";
import LoadingView from "../../common/LoadingView";
import ErrorView from "../../common/ErrorView";
import EmptyView from "../../common/EmptyView";
import { getCurrentWeekDates } from "../utils";
import WIPSection from "../../common/WIPSection";

const ParameterDetails = () => {
  const today = new Date().getDate();
  const [selectedMetric, setSelectedMetric] = useState("currentWeek");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [maxcalenderDate, SetMaxCalenderDate] = useState("");
  const [parameterData, setParameterData] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("total_count");
  const [limit, setLimit] = useState("15");
  const handleClose = () => setOpen(false);
  const handleShow = () => setOpen(true);

  const legendColors = ["#fcf99d", "#fa9f98"];
  const legendLabels = [">50%-80% Abnormal", ">80% Abnormal"];
  const loginData = JSON.parse(localStorage.getItem("credentials"));
  console.log("loginDataasw", loginData.displayName);

  const fetchParameterData = async () => {
    setLoader(true);
    // const apiUrl = `https://api.niroggyan.com/analytics/smartreport/parameter/counts?start=${startDate}&end=${endDate}&filter_by=count&order_by=total_count&limit=${parseInt(
    //   limit
    // )}`;

    const apiUrl = `https://api.niroggyan.com/analytics/smartreport/parameter/counts?start=${startDate}&end=${endDate}&filter_by=count&order_by=abnormal_count&limit=${parseInt(
      limit
    )} 
    `;

    const idToken = localStorage.getItem("idTokenBilling");

    let options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: idToken,
      },
    };
    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("Parameter", data);
      if (data?.data?.length === 0) {
        setLoader(false);
        setError("No data available for the chosen dates.");
      } else {
        setLoader(false);
        setError("");
        setParameterData(data.data);
      }
    } catch (e) {
      setLoader(false);
      console.log("Error", e);
      setError("Failed to fetch data. Please try again.");
    }
  };

  useEffect(() => {
    // if (today < 5) {
    //   setStartDate(getPreviousMonthStartEndDates().startDate);
    //   setEndDate(getPreviousMonthStartEndDates().endDate);
    //   setSelectedMetric("previousMonth");
    // } else {
    //   setStartDate(getCurrentMonthStartEndDates().startDate);
    //   setEndDate(getCurrentMonthStartEndDates().endDate);
    //   setSelectedMetric("currentMonth");
    // }
    setStartDate(getCurrentWeekDates().startDate);
    setEndDate(getCurrentWeekDates().endDate);
  }, []);

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     fetchParameterData();
  //   }
  // }, [startDate, endDate, limit]);

  const formatFromDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() >= 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 00:00:00.000`;
    console.log(requiredTime);
    return requiredTime;
  };

  const formatToDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() >= 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 23:59:59.999`;
    console.log(requiredTime);
    return requiredTime;
  };

  const getCurrentMonthStartEndDates = () => {
    let todaysDate = new Date();
    let startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      1,
      0,
      0,
      0,
      0
    );

    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(todaysDate),
    };
  };

  const getPreviousMonthStartEndDates = () => {
    let todaysDate = new Date();
    const startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth() - 1,
      1,
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      0,
      23,
      59,
      59,
      0
    );
    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(endDate),
    };
  };

  const setMaxDateCalender = (date) => {
    const dateFormat = new Date(date);
    const maxDate = new Date(dateFormat.setDate(dateFormat.getDate() + 31));
    const month =
      maxDate.getMonth() < 9
        ? `0${parseInt(maxDate.getMonth()) + 1}`
        : parseInt(maxDate.getMonth()) + 1;
    const day =
      maxDate.getDate() < 10 ? `0${maxDate.getDate()}` : maxDate.getDate();
    const formattedDate = `${maxDate.getFullYear()}-${month}-${day}`;

    SetMaxCalenderDate(formattedDate);
  };

  const customDateModal = () => {
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select Custom Date</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Box>
              <Typography mb={1}>From Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={startDate}
                onChange={(e) => {
                  setStartDate(formatFromDate(e.target.value));
                  setMaxDateCalender(e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography mb={1}>To Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={endDate}
                max={maxcalenderDate}
                onChange={(e) => setEndDate(formatToDate(e.target.value))}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button variant="contained" size="small" onClick={handleClose}>
            Save Changes
          </Button>
          <Button variant="contained" size="small" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleSelection = (e) => {
    setSelectedMetric(e.target.value);
    switch (e.target.value) {
      case "currentWeek":
        setStartDate(getCurrentWeekDates().startDate);
        setEndDate(getCurrentWeekDates().endDate);
        break;
      case "currentMonth":
        setStartDate(getCurrentMonthStartEndDates().startDate);
        setEndDate(getCurrentMonthStartEndDates().endDate);
        break;
      case "previousMonth":
        setStartDate(getPreviousMonthStartEndDates().startDate);
        setEndDate(getPreviousMonthStartEndDates().endDate);
        break;
      case "customMonth":
        setOpen(true);
        break;
    }
  };

  const handleLimitSelection = (e) => {
    setLimit(e.target.value);
  };

  const tableHeaders = [
    { id: "sl_no", label: "SN." },
    { id: "parameter_name", label: "Biomarker" },
    // { id: "normal_count", label: "Normal" },
    { id: "abnormal_count", label: "Abnormal" },
    { id: "total_count", label: "Total" },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  function descendingComparator(a, b, orderBy) {
    console.log("compar", a, b, orderBy);
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      console.log("order", order);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const parameterDataNumeric =
    parameterData &&
    parameterData.map((each) => {
      for (let [key, value] of Object.entries(each)) {
        if (!isNaN(value)) {
          each[key] = parseInt(value);
        }
      }
      return each;
    });

  const visibleRows = useMemo(() => {
    return stableSort(
      parameterData ? parameterDataNumeric : [],
      getComparator(order, orderBy)
    );
  }, [order, orderBy, parameterData, limit]);

  console.log("visibleRows", visibleRows);

  const createColoredRows = (each) => {
    if (Math.round((each.abnormal_count / each.total_count) * 100) > 80) {
      return "#fa9f98";
    } else if (
      Math.round((each.abnormal_count / each.total_count) * 100) > 50
    ) {
      return "#fcf99d";
    }
  };

  const colorLegend = () => {
    return (
      <Stack direction="row" gap={2} justifyContent="flex-end" mb={2} mt={2}>
        {legendColors.map((each, idx) => (
          <Legend key={idx} color={each} name={legendLabels[idx]} />
        ))}
      </Stack>
    );
  };

  return (
    <Box>
      {/* <Typography variant="h5" mb={2}>
        Biomarkers
      </Typography> */}
      <Stack
        my={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h2">Biomarkers</Typography>
        <Stack direction="row" gap={2}>
          {/* <FormControl size="small" variant="filled">
            <InputLabel id="demo-select-small-label">Top Packages</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={limit}
              label="Date"
              onChange={handleLimitSelection}
              sx={{ minWidth: "150px" }}
            >
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="10">10</MenuItem>
              <MenuItem value="15">15</MenuItem>
            </Select>
          </FormControl> */}
          <FormControl
            size="small"
            variant="filled"
            disabled={loginData?.disableParameterData}
          >
            <InputLabel id="demo-select-small-label">Time Period</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedMetric}
              label="Date"
              onChange={handleSelection}
            >
              <MenuItem value="currentWeek">Current Week</MenuItem>
              <MenuItem value="currentMonth">Current Month</MenuItem>
              <MenuItem value="previousMonth">Previous Month</MenuItem>
              <MenuItem value="customMonth">Custom Month</MenuItem>
            </Select>
          </FormControl>
          <Button
            onClick={fetchParameterData}
            disabled={
              loginData?.disableParameterData
                ? loginData?.disableParameterData
                : loader
            }
          >
            Get Details
          </Button>
          {customDateModal()}
        </Stack>
      </Stack>
      <Box>{colorLegend()}</Box>
      <Stack sx={{ width: "100%" }}>
        {loader ? (
          <Stack sx={{ height: "380px" }} justifyContent={"center"}>
            <LoadingView />
          </Stack>
        ) : error ? (
          <Stack justifyContent={"center"} sx={{ height: "380px" }}>
            <ErrorView error={error} />
          </Stack>
        ) : parameterData?.length ? (
          <>
            <TableContainer
              component={Paper}
              variant="outlined"
              sx={{
                maxHeight: "380px",
                overflowY: "auto",
                width: "100%",
                maxWidth: "100%",
                overflowX: "hidden",
                textOverflow: "ellipsis",
                borderStyle: "border-box",
                flexGrow: "1",
                boxShadow: "0px 1px 30px rgba(0,0,0,0.08)",
                // width: "max-content",
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((each, idx) => (
                      <TableCell
                        key={idx}
                        sortDirection={orderBy === each.id ? order : false}
                        align={idx > 1 ? "center" : "left"}
                        sx={{
                          width: idx === 0 ? "50px" : "inherit",
                          fontWeight: "600",
                          fontSize: "12px",
                        }}
                      >
                        {each.id !== "sl_no" ? (
                          <TableSortLabel
                            active={orderBy === each.id}
                            direction={orderBy === each.id ? order : "asc"}
                            onClick={createSortHandler(each.id)}
                          >
                            {each.label}
                            {orderBy === each.id ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          each.label
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visibleRows &&
                    visibleRows.map((each, idx) => (
                      <TableRow
                        key={idx}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          background: createColoredRows(each),
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          {idx + 1}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          {each.parameter_name}
                        </TableCell>
                        {/* <TableCell align="center">
                          {each.normal_count?.toLocaleString("en-IN")} {"("}
                          {Math.round(
                            (each.normal_count / each.total_count) * 100
                          )}
                          %{")"}
                        </TableCell> */}
                        <TableCell
                          align="center"
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          {each.abnormal_count?.toLocaleString("en-IN")} {"("}
                          {Math.round(
                            (each.abnormal_count / each.total_count) * 100
                          )}
                          %{")"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          {each.total_count?.toLocaleString("en-IN")}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* table row limit */}
            {/* <Paper>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={parameterDataNumeric.length}
                rowsPerPage={limit}
                page={0}
                onRowsPerPageChange={handleLimitSelection}
                // onPageChange={() => {}}
                labelDisplayedRows={() => null}
                sx={{
                  "& .MuiTablePagination-actions": {
                    visibility: "hidden",
                  },
                  "& .MuiTablePagination-selectLabel": {
                    marginTop: "16px",
                  },
                }}
              />
            </Paper> */}
          </>
        ) : (
          <Stack sx={{ height: "380px" }} justifyContent={"center"}>
            {loginData?.displayName === "Dummy User" ? (
              <WIPSection />
            ) : (
              <EmptyView message="Click on get details to load data" />
            )}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default ParameterDetails;
