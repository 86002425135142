import React, { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { content } from "../utils";

// let dataLoaded = false;

const YearWiseMetrics = ({ productType }) => {
  const [fetchedData, setFetchedData] = useState("");
  const [renderJsxStatus, setRenderJsxStatus] = useState(false);
  const [reRenderComponent, setReRenderComponent] = useState(false);
  const [loader, setLoader] = useState(false);
  // const [clientPreference, setClientPreference] = useState('');
  // const [orgPreference, setOrgPreference] = useState('');
  // const [clientIdPreference, setClientIdPreference] = useState('');

  window.addEventListener("storage", (e) => {
    setReRenderComponent(!reRenderComponent);
  });

  // fetch data from local storage
  useEffect(() => {
    // let clientPreferenceInfo = JSON.parse(
    //   localStorage.getItem('clientPreference')
    // );
    // let orgPreference = JSON.parse(localStorage.getItem('orgPreference'));

    // let clientIdPreference = JSON.parse(
    //   localStorage.getItem('clientIdPreference')
    // );

    if (true) {
      // setFetchedData(homepageData);
      setRenderJsxStatus(true);
    }
    // if (clientPreferenceInfo) {
    //   setClientPreference(clientPreferenceInfo);
    // }
    // if (orgPreference) {
    //   setOrgPreference(orgPreference);
    // }
    // if (clientIdPreference) {
    //   setClientIdPreference(clientIdPreference);
    // }
  }, []);

  const getMonthYearData = async () => {
    setLoader(true);
    const clientPreference = JSON.parse(
      localStorage.getItem("clientPreference")
    );
    const orgPreference = JSON.parse(localStorage.getItem("orgPreference"));

    const clientIdPreference = JSON.parse(
      localStorage.getItem("clientIdPreference")
    );
    const idToken = localStorage.getItem("idTokenBilling");

    const configBody = {
      headers: {
        authorization: idToken,
      },
      method: "get",
      url: `https://api.niroggyan.com/billing/${productType}/counts?query_on=counts&query_by=monthYear${
        orgPreference && clientPreference
          ? `&org=${orgPreference}&center=${clientPreference}`
          : ""
      }${clientIdPreference ? `&clientId=${clientIdPreference}` : ``}`,
      validateStatus: function (status) {
        return (status >= 200) & (status < 510);
      },
    };
    const response = await axios(configBody);
    setLoader(false);
    return response;
  };

  useEffect(() => {
    const storedYearWiseData = JSON.parse(localStorage.getItem("yearWiseData"));
    if (storedYearWiseData && Object.keys(storedYearWiseData)?.length) {
      setFetchedData(storedYearWiseData);
    } else {
      // dataLoaded &&
      (async function () {
        const response = await getMonthYearData();
        setFetchedData(response.data.data);
        localStorage.setItem(
          "yearWiseData",
          JSON.stringify(response.data.data)
        );
      })();
      // dataLoaded = true;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [productType]);

  // ACTUAL DATA
  let dataToFetch = fetchedData ? fetchedData : "";

  let arr = [];
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let pastMonthCountsData = dataToFetch ? dataToFetch : "";

  for (let year in pastMonthCountsData) {
    let counts = [];
    for (let month of months) {
      if (month in pastMonthCountsData[year]) {
        counts.push(pastMonthCountsData[year][month]);
      } else {
        counts.push(null);
      }
    }

    let obj = {
      name: year,
      data: counts,
    };
    arr.push(obj);
  }

  if (arr.length === 1) {
    let copyPreviousYearName = parseInt(arr[0].name);
    let dummyYear = copyPreviousYearName - 1;
    arr.push({
      name: dummyYear,
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
    });
  }

  let seriesData = arr;

  const totalReportGeneratedAllYear = [];

  // total reports generated till date
  for (let i = 0; i < seriesData.length; i++) {
    let getYearCountsArray = seriesData[i].data;
    for (let j = 0; j < getYearCountsArray.length; j++) {
      let theCount = getYearCountsArray[j];
      if (theCount === "null" || theCount === null || theCount === undefined) {
        //
      } else {
        totalReportGeneratedAllYear.push(theCount);
      }
    }
  }

  const sumOfTotalReportGeneratedAllYear = totalReportGeneratedAllYear.reduce(
    (a, b) => parseInt(a) + parseInt(b),
    0
  );

  let lineChartData = {
    series: seriesData,
    options: {
      fill: {
        // type: 'gradient',
        // gradient: {
        //     shade: 'dark',
        //     gradientToColors: ['#FDD835'],
        //     shadeIntensity: 1,
        //     type: 'horizontal',
        //     opacityFrom: 1,
        //     opacityTo: 1,
        //     stops: [0, 100, 100, 100]
        // },
      },
      chart: {
        id: "YearWiseData",
        height: 330,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [5, 7, 5],
        curve: "straight",
        //dashArray: [0, 8, 5]
      },
      title: {
        text: "",
        align: "left",
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          console.log("val", val, opts);
          return (
            val +
            " - " +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            ""
          );
        },
      },
      markers: {
        size: 5,
        hover: {
          sizeOffset: 6,
        },
      },
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      // title: {
      //   text: '',
      // },
      xaxis: {
        // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep',
        //     'Oct', 'Nov', 'Dec'],
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + " Reports Generated:";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val + " Reports Generated:";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  };

  const renderJSX = () => {
    return (
      <>
        {/* <div style={{ display: "inline-flex" }} className="mb-4">
          <div>
            <Card
              style={{ borderRadius: "15px", marginRight: "30px" }}
              className="customShadowCss"
            >
              <Card.Body>
                <Card.Title
                  style={{
                    fontWeight: "unset",
                    fontSize: "14px",
                    width: "140px",
                  }}
                >
                  {"All Year Total"}
                </Card.Title>

                <Card.Subtitle>
                  {sumOfTotalReportGeneratedAllYear}
                  <span
                    style={{
                      fontWeight: "550",
                      color: "#7787bc",
                      fontSize: "12.5px",
                    }}
                  >
                    {" Reports"}
                  </span>
                </Card.Subtitle>
              </Card.Body>
            </Card>
          </div>
        </div> */}

        <div>
          <Card style={{ border: "none", borderRadius: "8px" }}>
            <Card.Body style={{ padding: "24px" }}>
              <Card.Title className="mb-4">
                <Tooltip
                  title={content.yearlyReport.tooltipText}
                  placement="bottom"
                >
                  <Typography
                    variant="h2"
                    sx={{ display: "inline-block", cursor: "pointer" }}
                  >
                    {content.yearlyReport.title}
                  </Typography>
                </Tooltip>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }} my={2}>
                  {content.yearlyReport.subHeading}
                </Typography>
              </Card.Title>
              <Card.Subtitle>
                {sumOfTotalReportGeneratedAllYear === 0 && !loader ? (
                  <span style={{ color: "#7787bc" }}>
                    {"No Data to display"}
                  </span>
                ) : loader ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <div>
                    {/* <div style={{ display: "inline-flex" }} className="mb-4">
                    <div>
                      <Card
                        style={{ borderRadius: "15px", marginRight: "30px" }}
                        className="customShadowCss"
                      >
                        <Card.Body>
                          <Card.Title
                            style={{
                              fontWeight: "unset",
                              fontSize: "14px",
                              width: "140px",
                            }}
                          >
                            {"All Year Total"}
                          </Card.Title>

                          <Card.Subtitle>
                            {sumOfTotalReportGeneratedAllYear.toLocaleString(
                              "en-IN"
                            )}
                            <span
                              style={{
                                fontWeight: "550",
                                color: "#7787bc",
                                fontSize: "12.5px",
                              }}
                            >
                              {" Reports"}
                            </span>
                          </Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </div>
                  </div> */}
                    <Stack
                      sx={{
                        borderRadius: "16px",
                        border: "1px solid #E8E7E7",

                        width: "250px",
                      }}
                    >
                      <Stack
                        direction="row"
                        gap={3}
                        sx={{ padding: "16px 16px 0px 16px" }}
                      >
                        <Stack
                          justifyContent="center"
                          alignItems="center"
                          gap={3}
                          sx={{
                            width: "58px",
                            height: "58px",
                            borderRadius: "6px",
                            background: "#F8DEBD",
                          }}
                        >
                          <AssignmentIcon sx={{ color: "#BA8139" }} />
                        </Stack>
                        <Box>
                          <Typography variant="h6">All Year Total </Typography>
                          <Typography variant="h4">
                            {sumOfTotalReportGeneratedAllYear.toLocaleString(
                              "en-IN"
                            )}
                          </Typography>
                        </Box>
                      </Stack>
                      <img
                        src="https://niroggyan.s3.ap-south-1.amazonaws.com/new-billing-dashboard-files/logos/report_count_trend1.png"
                        alt="trend"
                      />
                    </Stack>

                    <ReactApexChart
                      options={lineChartData.options}
                      series={lineChartData.series}
                      type="line"
                      height={330}
                    />
                  </div>
                )}
              </Card.Subtitle>
            </Card.Body>
          </Card>
        </div>
      </>
    );
  };

  return <span>{renderJsxStatus ? renderJSX() : ""}</span>;
};

export default YearWiseMetrics;
