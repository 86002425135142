import React, { useEffect, useState } from "react";
import ClientDistributonSection from "./VizAppDashboardSections/ClientDistributionSection";
import MetricsSection from "./VizAppDashboardSections/MetricsSection";
import PlanDetailsSection from "./VizAppDashboardSections/PlanDetailsSection";
import TopFivePackageSection from "./VizAppDashboardSections/TopFivePackageSection";
import WelcomeScreenAndPlanSection from "./VizAppDashboardSections/WelcomeScreenAndPlanSection";
import YearWiseMetrics from "./VizAppDashboardSections/YearWiseMetrics";
import TableComponent from "./TableComponent";

import MaintenancePage from "./maintenance";
import LoginOAuth from "./VizAppDashboardSections/loginPageOAuth";
import OnboardingStatus from "./onboardingStatus/OnboardingStatus";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import NavbarComponent from "./NavbarComponent";
import VizAppReviews from "./VizAppDashboardSections/VizAppReviews";
import UserTimeline from "./VizAppDashboardSections/UserTimeline";
import PageTitle from "../common/PageTitle";
import SmartReportFeedback from "./VizAppDashboardSections/SmartReportFeedback";
import ReportGenerationTAT from "./AnalyticsSection/ReportGenerationTAT";

const VizAppDashboard = () => {
  const loginData = JSON.parse(localStorage.getItem("loginDetails"));
  const defaultProductType = loginData?.productType
    ? loginData?.productType
    : "smartreport";
  const [clientPreferenceRecord, setClientPreferenceRecord] = useState(false);
  const [reRenderComponent, setReRenderComponent] = useState(false);
  const [roleInfo, setRoleInfo] = useState("");
  const [trialClientData, setTrialClientData] = useState("");
  const [productType, SetProductType] = useState(defaultProductType);

  window.addEventListener("storage", (e) => {
    // console.log("something got changed.")
    setReRenderComponent(!reRenderComponent);
  });

  useEffect(() => {
    let clientPreferenceInfo = JSON.parse(
      localStorage.getItem("clientPreference")
    );
    let loginInfo = JSON.parse(localStorage.getItem("loginDetails"));
    let trialClientInfo = JSON.parse(
      localStorage.getItem("trialClientPreference")
    );

    if (clientPreferenceInfo) {
      setClientPreferenceRecord(clientPreferenceInfo);
    }

    if (loginInfo) {
      console.log("role:", loginInfo.role);
      setRoleInfo(loginInfo.role);
    }

    if (trialClientInfo) {
      setTrialClientData(trialClientInfo);
    }
  }, [reRenderComponent]);

  console.log("here:", localStorage.getItem("logoUrl"));

  return (
    <>
      <Stack direction="row" gap={0}>
        <NavbarComponent />
        {trialClientData === "yes" ? (
          <>
            <TableComponent />
          </>
        ) : (
          <Box flexGrow={1} sx={{ padding: "0px 64px 64px" }}>
            <Box>
              {/* <InputLabel sx={{color: "#170C6B", paddingBottom:"16px", fontSize:"22px", fontWeight:"500"}}>Select Product</InputLabel>
            <Select
               sx={{color: "#3567d6"}}
               onChange={e => SetProductType(e.target.value)}
               value={productType}
            >
              <MenuItem sx={{color: "#3567d6"}} value={'vizapp'}>Viz App</MenuItem>
              <MenuItem sx={{color: "#3567d6"}} value={'smartreport'}>Smart Report</MenuItem>
              <MenuItem sx={{color: "#3567d6"}} value={'videoreport'}>Video Report</MenuItem>
            </Select> */}

              {/* <LoginOAuth /> */}
              {/* <Typography variant="h3" mb={3}>
                Overview
              </Typography> */}
              {/* welcome section */}
              <Box
                sx={{
                  position: "sticky",
                  top: "0",
                  zIndex: 100,
                  background: "#E9F5FE",
                  padding: "40px 16px 18px",
                  margin: "0px -16px",
                  display: "none",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  <Typography variant="h1">
                    Welcome{" "}
                    {loginData?.displayName ? loginData?.displayName : ""}
                  </Typography>
                  {loginData && loginData.role === "lab" ? (
                    <Stack direction="row" gap={3} alignItems="center">
                      {/* <Box
              sx={{
                padding: "10px 14px",
                background: "#fff",
                borderRadius: "8px",
                textAlign: "center",
              }}
            >
              <NotificationsIcon />
            </Box> */}
                      <Box
                        sx={{
                          padding: "10px 14px",
                          background: "#fff",
                          borderRadius: "8px",
                          textAlign: "center",
                        }}
                      >
                        {loginData && loginData.role === "lab" ? (
                          <img
                            src={loginData.logoSrc}
                            alt={loginData.center}
                            height="50px"
                            width="150px"
                            style={{ objectFit: "contain" }}
                          />
                        ) : (
                          ""
                        )}
                      </Box>
                    </Stack>
                  ) : (
                    ""
                  )}
                </Stack>
                {/* select product section */}
                <Stack direction="row" gap={2} alignItems="center" my={2}>
                  <Typography variant="body4">Select Product</Typography>
                  <FormControl size="small" sx={{ width: "200px" }}>
                    {/* <InputLabel id="demo-select-small-label">
                    Time Period
                  </InputLabel> */}
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={productType}
                      label=""
                      variant="outlined"
                      onChange={(e) => SetProductType(e.target.value)}
                    >
                      <MenuItem value="smartreport">Smart Report</MenuItem>
                      <MenuItem value="vizapp">Viz App</MenuItem>
                      <MenuItem value="videoreport">
                        Smart Video Report
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Box>

              <PageTitle
                title={`Welcome ${
                  loginData?.displayName ? loginData?.displayName : ""
                }`}
              />

              {/* choose product */}
              <Stack direction="row" gap={2} alignItems="center" mb={3}>
                <Typography variant="body4">Select Product</Typography>
                <FormControl size="small" sx={{ width: "200px" }}>
                  {/* <InputLabel id="demo-select-small-label">
                    Time Period
                  </InputLabel> */}
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={productType}
                    label=""
                    variant="outlined"
                    onChange={(e) => SetProductType(e.target.value)}
                  >
                    <MenuItem value="smartreport">Smart Report</MenuItem>
                    <MenuItem value="vizapp">Viz App</MenuItem>
                    <MenuItem value="videoreport">Smart Video Report</MenuItem>
                  </Select>
                </FormControl>
              </Stack>

              {/* <OnboardingStatus /> */}

              {/* timeline section  */}
              {/* {loginData.role !== "admin" && (
                <Stack mb={3}>
                  <UserTimeline />
                </Stack>
              )} */}

              <WelcomeScreenAndPlanSection productType={productType} />
              <Stack mt={3}>
                {/* <TopFivePackageSection productType={productType} /> */}
                <MetricsSection productType={productType} />
              </Stack>

              {clientPreferenceRecord === "none" && roleInfo !== "lab" ? (
                <ClientDistributonSection productType={productType} />
              ) : (
                ""
              )}
              <Stack mt={3}>
                <YearWiseMetrics productType={productType} />
              </Stack>

              {/* <Stack mt={3}>
                <ReportGenerationTAT />
              </Stack> */}

              {/* dangs viz app review */}
              {productType === "vizapp" && loginData.center === "dangs" && (
                <Stack
                  mt={3}
                  p={3}
                  sx={{ background: "#fff", borderRadius: "8px" }}
                >
                  <VizAppReviews />
                </Stack>
              )}

              {loginData?.role === "lab" && productType === "smartreport" && (
                <Stack
                  mt={3}
                  p={3}
                  sx={{ background: "#fff", borderRadius: "8px" }}
                >
                  <SmartReportFeedback />
                </Stack>
              )}
            </Box>
          </Box>
        )}
        {/* <MaintenancePage /> */}
      </Stack>
    </>
  );
};

export default VizAppDashboard;
