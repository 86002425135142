import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import { useEffect, useMemo, useState } from "react";

import LoadingView from "../../common/LoadingView";
import ErrorView from "../../common/ErrorView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CustomBox, CustomShadowBox } from "../../common/BoxAbstraction";
import EmptyView from "../../common/EmptyView";
import CollapsableHeader from "../../common/CollapsableHeader";
import { content, getCurrentWeekDates } from "../utils";

const ReportGenerationTAT = () => {
  const today = new Date().getDate();
  const [selectedMetric, setSelectedMetric] = useState("currentWeek");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [maxcalenderDate, SetMaxCalenderDate] = useState("");
  const [open, setOpen] = useState(false);
  const [tat, setTat] = useState("");
  const handleClose = () => setOpen(false);
  const handleShow = () => setOpen(true);

  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);

  const fetchTatData = async () => {
    setLoader(true);
    setError("");
    const apiUrl = `https://at8ed64ird.execute-api.ap-south-1.amazonaws.com/production/smartreport/operational/generation?start=${startDate}&end=${endDate}`;

    const idToken = localStorage.getItem("idTokenBilling");

    let options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: idToken,
      },
    };

    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("tatadata", data);
      if (data?.data) {
        setTat(data?.data[0].average_generation_sec);
      }
    } catch (e) {
      console.log("error", e);
      setError("Something went wrong. Please try again!");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    // if (today < 5) {
    //   setStartDate(getPreviousMonthStartEndDates().startDate);
    //   setEndDate(getPreviousMonthStartEndDates().endDate);
    //   setSelectedMetric("previousMonth");
    // } else {
    //   setStartDate(getCurrentMonthStartEndDates().startDate);
    //   setEndDate(getCurrentMonthStartEndDates().endDate);
    //   setSelectedMetric("currentMonth");
    // }
    setStartDate(getCurrentWeekDates().startDate);
    setEndDate(getCurrentWeekDates().endDate);
  }, []);

  // useEffect(() => {
  //   fetchTatData();
  // }, [startDate, endDate, selectedMetric]);

  const formatFromDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() >= 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 00:00:00.000`;
    console.log(requiredTime);
    return requiredTime;
  };

  const formatToDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() >= 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 23:59:59.999`;
    console.log(requiredTime);
    return requiredTime;
  };

  const getCurrentMonthStartEndDates = () => {
    let todaysDate = new Date();
    let startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      1,
      0,
      0,
      0,
      0
    );

    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(todaysDate),
    };
  };

  const getPreviousMonthStartEndDates = () => {
    let todaysDate = new Date();
    const startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth() - 1,
      1,
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      0,
      23,
      59,
      59,
      0
    );
    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(endDate),
    };
  };

  const setMaxDateCalender = (date) => {
    const dateFormat = new Date(date);
    const maxDate = new Date(dateFormat.setDate(dateFormat.getDate() + 31));
    const month =
      maxDate.getMonth() < 9
        ? `0${parseInt(maxDate.getMonth()) + 1}`
        : parseInt(maxDate.getMonth()) + 1;
    const day =
      maxDate.getDate() < 10 ? `0${maxDate.getDate()}` : maxDate.getDate();
    const formattedDate = `${maxDate.getFullYear()}-${month}-${day}`;

    SetMaxCalenderDate(formattedDate);
  };

  const customDateModal = () => {
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select Custom Date</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Box>
              <Typography mb={1}>From Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={startDate}
                onChange={(e) => {
                  setStartDate(formatFromDate(e.target.value));
                  setMaxDateCalender(e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography mb={1}>To Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={endDate}
                max={maxcalenderDate}
                onChange={(e) => setEndDate(formatToDate(e.target.value))}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button variant="contained" size="small" onClick={handleClose}>
            Save Changes
          </Button>
          <Button variant="contained" size="small" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleSelection = (e) => {
    setSelectedMetric(e.target.value);
    switch (e.target.value) {
      case "currentWeek":
        setStartDate(getCurrentWeekDates().startDate);
        setEndDate(getCurrentWeekDates().endDate);
        break;
      case "currentMonth":
        setStartDate(getCurrentMonthStartEndDates().startDate);
        setEndDate(getCurrentMonthStartEndDates().endDate);
        break;
      case "previousMonth":
        setStartDate(getPreviousMonthStartEndDates().startDate);
        setEndDate(getPreviousMonthStartEndDates().endDate);
        break;
      case "customMonth":
        setOpen(true);
        break;
    }
  };

  function formatTime(seconds) {
    const days = Math.floor(seconds / (24 * 60 * 60));
    seconds %= 24 * 60 * 60;
    const hours = Math.floor(seconds / (60 * 60));
    seconds %= 60 * 60;
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;

    return `${days} days ${hours} hours ${minutes} minutes ${Math.floor(
      seconds
    )} seconds`;
  }

  console.log("tat", tat);

  return (
    <Box>
      <Accordion component="Box">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {/* <Typography variant="h2">Report TAT</Typography> */}
          <CollapsableHeader
            title={content.reportTat.title}
            subheading={content.reportTat.subHeading}
            tooltipText={content.reportTat.tooltipText}
          />
        </AccordionSummary>
        <AccordionDetails>
          <CustomBox>
            <CustomShadowBox mb={2}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{ width: "100%" }}
                gap={2}
              >
                <FormControl size="small" variant="filled">
                  <InputLabel id="demo-select-small-label">
                    Time Period
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedMetric}
                    label="Date"
                    onChange={handleSelection}
                  >
                    <MenuItem value="currentWeek">Current Week</MenuItem>
                    <MenuItem value="currentMonth">Current Month</MenuItem>
                    <MenuItem value="previousMonth">Previous Month</MenuItem>
                    <MenuItem value="customMonth">Custom Month</MenuItem>
                  </Select>
                </FormControl>
                <Button onClick={fetchTatData} disabled={loader}>
                  Get Details
                </Button>
                {customDateModal()}
              </Stack>
            </CustomShadowBox>
            <Stack>
              {loader ? (
                <LoadingView />
              ) : error ? (
                <ErrorView />
              ) : tat ? (
                <Typography variant="body9" sx={{ padding: "12px 20px" }}>
                  Average Report Generation Time: {formatTime(tat)}
                </Typography>
              ) : (
                <EmptyView message="Click on get details to load data" />
              )}
            </Stack>
          </CustomBox>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ReportGenerationTAT;
