/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Card, Modal, Spinner } from "react-bootstrap";
import CalenderSvg from "../../svgs/CalenderSvg";
import "../../css/metricsSectionCss.css";
import ReportsGeneratedSvgIcon from "../../svgs/ReportsGeneratedSvgIcon";
import axios from "axios";
import DownloadSvgSmall from "../../svgs/DownloadSvgSmall";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import YearWiseMetrics from "./YearWiseMetrics";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DownloadIcon from "@mui/icons-material/Download";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { content, getCurrentWeekDates } from "../utils";

// let dataLoaded = false;

const MetricsSection = ({ productType }) => {
  console.log("productType1", productType);
  const today = new Date().getDate();
  const [currentMonthSelection, setCurrentMonthSelection] = useState(true);
  const [customMonthSelection, setCustomMonthSelection] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState(false);
  const [reRenderComponent, setReRenderComponent] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [epochFromTIme, setEpochFromTime] = useState("");
  const [epochToTIme, setEpochToTime] = useState("");
  const [maxcalenderDate, SetMaxCalenderDate] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState("currentWeek");

  // Function for epoch TIme

  const formatFromDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() > 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 00:00:00.000`;
    console.log(requiredTime);
    return requiredTime;
  };
  const formatToDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() > 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 23:59:59.999`;
    console.log(requiredTime);
    return requiredTime;
  };

  window.addEventListener("storage", (e) => {
    // console.log("something got changed.")
    setReRenderComponent(!reRenderComponent);
  });

  const [fetchedData, setFetchedData] = useState([]);
  const [prevMonthData, setPrevMonthData] = useState("");
  const [loginData, setLoginData] = useState("");
  const [loadingGraphValuesStatus, setLoadingGraphValuesStatus] =
    useState(false);

  // modal state and functions
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // fetch data from local storage
  useEffect(() => {
    const today = new Date().getDate();
    // if (today < 5) {
    //   setCurrentMonthSelection(false);
    //   setCustomMonthSelection(false);
    // } else {
    //   setCurrentMonthSelection(true);
    //   setCustomMonthSelection(false);
    // }
    setCurrentMonthSelection(false);

    let loginDataInfo = JSON.parse(localStorage.getItem("loginDetails"));

    if (loginDataInfo) {
      setLoginData(loginDataInfo);
    }
  }, [reRenderComponent]);

  const customDatesDayWiseCounts = async () => {
    handleClose();
    setLoadingGraphValuesStatus(true);
    const response = await getDayWiseCounts(epochFromTIme, epochToTIme);
    setFetchedData(response.data.data);
    setCustomMonthSelection(true);
    setLoadingGraphValuesStatus(false);
  };

  const getCurrentMonthStartEndDates = () => {
    let todaysDate = new Date();
    let startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      1,
      0,
      0,
      0,
      0
    );

    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(todaysDate),
    };
  };

  const getPreviousMonthStartEndDates = () => {
    let todaysDate = new Date();
    const startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth() - 1,
      1,
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      0,
      23,
      59,
      59,
      0
    );
    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(endDate),
    };
  };

  const getDayWiseCounts = async (startDate, endDate) => {
    setLoader(true);
    const clientPreference = JSON.parse(
      localStorage.getItem("clientPreference")
    );
    const orgPreference = JSON.parse(localStorage.getItem("orgPreference"));

    const clientIdPreference = JSON.parse(
      localStorage.getItem("clientIdPreference")
    );
    const idToken = localStorage.getItem("idTokenBilling");
    const configBody = {
      headers: {
        authorization: idToken,
      },
      method: "get",
      url: `https://api.niroggyan.com/billing/${productType}/counts?query_on=counts&query_by=range&start=${startDate}&end=${endDate}${
        orgPreference && clientPreference
          ? `&org=${orgPreference}&center=${clientPreference}`
          : ""
      }${clientIdPreference ? `&clientId=${clientIdPreference}` : ``}`,
      validateStatus: function (status) {
        return (status >= 200) & (status < 510);
      },
    };
    const response = await axios(configBody);
    setLoader(false);
    return response;
  };

  const currentWeekDayWiseCounts = async () => {
    const startDate = getCurrentWeekDates().startDate;
    const endDate = getCurrentWeekDates().endDate;
    (async function () {
      const response = await getDayWiseCounts(startDate, endDate);
      setFetchedData(response.data.data);
    })();
  };

  const currentMonthDayWiseCounts = async () => {
    const startDate = getCurrentMonthStartEndDates().startDate;
    const endDate = getCurrentMonthStartEndDates().endDate;
    (async function () {
      const response = await getDayWiseCounts(startDate, endDate);
      setFetchedData(response.data.data);
    })();
  };

  const previousMonthDayWiseCounts = async () => {
    const startDate = getPreviousMonthStartEndDates().startDate;
    const endDate = getPreviousMonthStartEndDates().endDate;
    (async function () {
      const response = await getDayWiseCounts(startDate, endDate);
      setFetchedData(response.data.data);
    })();
  };

  // useEffect(() => {
  //   const storedCurrentMonthData = JSON.parse(
  //     localStorage.getItem("currentMonthReportData")
  //   );
  //   const storedPrevMonthData = JSON.parse(
  //     localStorage.getItem("prevMonthReportData")
  //   );

  //   if (storedCurrentMonthData?.length && storedPrevMonthData?.length) {
  //     setFetchedData(storedCurrentMonthData);
  //     setPrevMonthData(storedPrevMonthData);
  //   } else {
  //     const today = new Date().getDate();
  //     if (today < 5) {
  //       setSelectedMetric("previousMonth");
  //     }
  //     const startDate =
  //       today < 5
  //         ? getPreviousMonthStartEndDates().startDate
  //         : getCurrentMonthStartEndDates().startDate;
  //     const endDate =
  //       today < 5
  //         ? getPreviousMonthStartEndDates().endDate
  //         : getCurrentMonthStartEndDates().endDate;

  //     // dataLoaded &&
  //     (async function () {
  //       const response = await getDayWiseCounts(startDate, endDate);
  //       console.log("response", response);
  //       setFetchedData(response.data.data);
  //       localStorage.setItem(
  //         "currentMonthReportData",
  //         JSON.stringify(response.data.data)
  //       );
  //     })();
  //     // dataLoaded = true;

  //     //premonth data
  //     (async function () {
  //       const response = await getDayWiseCounts(
  //         getPreviousMonthStartEndDates().startDate,
  //         getPreviousMonthStartEndDates().endDate
  //       );
  //       console.log("responseprev", response);
  //       setPrevMonthData(response.data.data);
  //       localStorage.setItem(
  //         "prevMonthReportData",
  //         JSON.stringify(response.data.data)
  //       );
  //     })();
  //   }
  // }, [productType]);

  useEffect(() => {
    if (selectedMetric === "currentMonth") {
      const storedPrevMonthData = JSON.parse(
        localStorage.getItem("prevMonthReportData")
      );
      if (storedPrevMonthData?.length) {
        setPrevMonthData(storedPrevMonthData);
      } else {
        (async function () {
          const response = await getDayWiseCounts(
            getPreviousMonthStartEndDates().startDate,
            getPreviousMonthStartEndDates().endDate
          );

          setPrevMonthData(response.data.data);
          localStorage.setItem(
            "prevMonthReportData",
            JSON.stringify(response.data.data)
          );
        })();
      }
    }
  }, [selectedMetric]);

  useEffect(() => {
    const storedCurrentWeekData = JSON.parse(
      localStorage.getItem("currentWeekReportData")
    );
    if (storedCurrentWeekData?.length) {
      setFetchedData(storedCurrentWeekData);
    } else {
      const startDate = getCurrentWeekDates().startDate;
      const endDate = getCurrentWeekDates().endDate;
      (async function () {
        const response = await getDayWiseCounts(startDate, endDate);
        console.log("response", response);
        setFetchedData(response.data.data);
        localStorage.setItem(
          "currentWeekReportData",
          JSON.stringify(response.data.data)
        );
      })();
    }
  }, [productType]);

  // using actual endpoint data
  let reportCountMonth = fetchedData
    ? fetchedData.map((each) => parseInt(each.count))
    : [];
  let datesOfMonth = fetchedData
    ? fetchedData.map((each) =>
        each.date.split("-").reverse().join("-").slice(0, -5)
      )
    : [];

  let yAxisDates;
  // Selecting the month and year to display in graph and also find average results
  let reportCount; // for x-axis and y-axis (col bar)
  let textToDisplay;
  let titleText;
  let averageToDisplay;
  let numberOfReportsInMonth;

  // else choose current month data
  reportCount = reportCountMonth;
  yAxisDates = datesOfMonth;

  let totalCurrentCounts = reportCountMonth.reduce(
    (a, b) => parseInt(a) + parseInt(b),
    0
  );
  numberOfReportsInMonth = totalCurrentCounts;
  let totalDaysCurrentMonth = datesOfMonth.length;

  if (totalCurrentCounts === 0 && totalDaysCurrentMonth === 0) {
    averageToDisplay = 0;
  } else {
    averageToDisplay = Math.round(totalCurrentCounts / totalDaysCurrentMonth);
  }
  textToDisplay = "Monthly Report Count by Day";
  if (selectedMetric === "previousMonth") {
    // textToDisplay = "Previous Month Day Wise Data";
  } else if (selectedMetric === "yearly") {
    textToDisplay = "Year and Month Wise Data";
  }

  titleText = "Get Current Month detailed info";

  const getPrevMonthAvg = () => {
    const counts = prevMonthData.map((each) => each.count);
    const totalCount = counts.reduce((a, b) => parseInt(a) + parseInt(b));
    const prevMonthAvg = Math.round(totalCount / prevMonthData.length);
    const percentageVar =
      ((prevMonthAvg - averageToDisplay) / prevMonthAvg) * 100;

    console.log("percentageVar", totalCount, prevMonthAvg);

    return {
      prevMonthAvg: prevMonthAvg,
      percentageVar: Math.abs(percentageVar.toFixed(2)),
    };
  };

  // column graph properties
  let dataToRender = {
    options: {
      dataLabels: {
        enabled: false,
        dropShadow: {
          enabled: true,
          left: 2,
          top: 2,
          opacity: 0.5,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "30%",
          borderRadius: 4,
          borderRadiusApplication: "around",
        },
      },
      // annotations: {
      //   points: [
      //     {
      //       x: yAxisDates[yAxisDates.length - 1],
      //       y: reportCount[reportCount.length - 1],
      //       marker: {
      //         size: 6,
      //         fillColor: "#fff",
      //         strokeColor: "#F80D38",
      //         strokeWidth: 5,
      //         radius: 2,
      //         OffsetY: 10,
      //       },
      //     },
      //   ],
      // },
      chart: {
        id: "MonthWise",
      },
      xaxis: {
        categories: yAxisDates,
      },
      colors: ["#478F96"],
    },

    series: [
      {
        name: "Reports Generated",
        data: reportCount,
      },
    ],
  };

  // download metrics data
  const downloadMetricsHandler = async () => {
    const idToken = localStorage.getItem("idTokenBilling");
    if (customMonthSelection) {
      setDownloadStatus(true);
      let customMonthEndPoint = `https://api.niroggyan.com/billing/${productType}/data?query_on=counts&query_by=range&start=${epochFromTIme}&end=${epochToTIme}`;

      var configForCustomMonth = {
        method: "GET",
        url: customMonthEndPoint,
        headers: {
          authorization: idToken,
          "Content-Type": "application/json",
        },
      };

      try {
        const axiosDataCustom = await axios(configForCustomMonth);
        const requiredFileLink = axiosDataCustom.data.data;
        const link = document.createElement("a");
        link.href = requiredFileLink;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownloadStatus(false);
      } catch (error) {
        setDownloadStatus(false);
      }
    } else if (!currentMonthSelection) {
      setDownloadStatus(true);
      const { startDate, endDate } = getPreviousMonthStartEndDates();
      let previousMonthEndPoint = `https://api.niroggyan.com/billing/${productType}/data?query_on=counts&query_by=range&start=${startDate}&end=${endDate}`;
      var configForPreviousMonth = {
        method: "GET",
        url: previousMonthEndPoint,
        headers: {
          authorization: idToken,
          "Content-Type": "application/json",
        },
      };

      try {
        const axiosDataPrevious = await axios(configForPreviousMonth);
        const requiredFileLink = axiosDataPrevious.data.data;
        const link = document.createElement("a");
        link.href = requiredFileLink;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setDownloadStatus(false);
      } catch (error) {
        setDownloadStatus(false);
        console.log("axiosDataPrevious Error: ", error);
      }
    } else {
      setDownloadStatus(true);
      const { startDate, endDate } = getCurrentMonthStartEndDates();
      let currentMonthEndPoint = `https://api.niroggyan.com/billing/${productType}/data?query_on=counts&query_by=range&start=${startDate}&end=${endDate}`;
      var configForCurrentMonth = {
        method: "GET",
        url: currentMonthEndPoint,
        headers: {
          "Content-Type": "application/json",
          authorization: idToken,
        },
      };

      try {
        const axiosDataCurrent = await axios(configForCurrentMonth);
        const requiredFileLink = axiosDataCurrent.data.data;
        const link = document.createElement("a");
        link.href = requiredFileLink;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownloadStatus(false);
      } catch (error) {
        setDownloadStatus(false);
        console.log("axiosDataCurrent Error: ", error);
      }
    }
  };

  const setMaxDateCalender = (date) => {
    const dateFormat = new Date(date);
    const maxDate = new Date(dateFormat.setDate(dateFormat.getDate() + 31));
    const month =
      maxDate.getMonth() < 9
        ? `0${parseInt(maxDate.getMonth()) + 1}`
        : parseInt(maxDate.getMonth()) + 1;
    const day =
      maxDate.getDate() < 10 ? `0${maxDate.getDate()}` : maxDate.getDate();
    const formattedDate = `${maxDate.getFullYear()}-${month}-${day}`;

    SetMaxCalenderDate(formattedDate);
  };

  const handleSelection = async (e) => {
    setSelectedMetric(e.target.value);
    switch (e.target.value) {
      case "currentWeek":
        setCurrentMonthSelection(false);
        setCustomMonthSelection(false);
        await currentWeekDayWiseCounts();
        break;
      case "currentMonth":
        setCurrentMonthSelection(true);
        setCustomMonthSelection(false);
        // !currentMonthSelection && (await currentMonthDayWiseCounts());
        if (!currentMonthSelection) {
          await currentMonthDayWiseCounts();
          // await previousMonthDayWiseCounts();
        }
        break;
      case "previousMonth":
        setCurrentMonthSelection(false);
        setCustomMonthSelection(false);
        await previousMonthDayWiseCounts();
        break;
      case "customMonth":
        handleShow(true);
        setCurrentMonthSelection(false);
        break;
      default:
    }
  };

  const oldModal = () => {
    return (
      <div>
        <>
          <Modal
            show={false}
            onHide={handleClose}
            dialogClassName="custom-modal"
            centered
            style={{ zIndex: 1500 }}
          >
            <Modal.Header closeButton>
              <Modal.Title className="modalHeading">
                {"Select Custom Date"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* For selecting From Date */}

              <label className="labelTags" htmlFor="FromDate">
                From Date to tyjj
              </label>
              <input
                id="FromDate"
                className="customDateTag"
                type="date"
                placeholder="DD-MM-YYYY"
                defaultValue={selectedFromDate}
                onChange={(e) => {
                  setSelectedFromDate(e.target.value);
                  setEpochFromTime(formatFromDate(e.target.value));
                  setMaxDateCalender(e.target.value);
                }}
              />
              {/* For selecting TO Date */}
              <label className="labelTags" htmlFor="ToDate">
                To Date
              </label>
              <input
                id="ToDate"
                className="customDateTag"
                type="date"
                max={maxcalenderDate}
                placeholder="DD-MM-YYYY"
                defaultValue={selectedToDate}
                onChange={(e) => {
                  setSelectedToDate(e.target.value);
                  setEpochToTime(formatToDate(e.target.value));
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary btn-sm"
                onClick={async () => {
                  await customDatesDayWiseCounts();
                }}
              >
                Save Changes
              </Button>
              <Button variant="info btn-sm" onClick={handleClose}>
                {"Cancel"}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </div>
    );
  };

  const showVariation = () => {
    const { prevMonthAvg, percentageVar } = getPrevMonthAvg();
    const isGreater = averageToDisplay > prevMonthAvg;
    const isEqual = averageToDisplay === prevMonthAvg;
    return (
      <Stack direction="row" gap={0.5} alignItems="center">
        <Typography
          variant="h6"
          mt={0.5}
          color={isGreater ? "#0cc437" : isEqual ? "" : "error"}
        >
          {percentageVar}%
        </Typography>
        <Box>
          {isGreater ? (
            <ArrowUpwardIcon sx={{ color: "#0cc437" }} fontSize="small" />
          ) : isEqual ? (
            ""
          ) : (
            <ArrowDownwardIcon color="error" fontSize="small" />
          )}
        </Box>
      </Stack>
    );
  };

  return (
    <div style={{ flexGrow: 1 }}>
      {/* <div style={{ display: "inline-flex" }} className="mb-4">
        <div>
          <Card
            style={{
              borderRadius: "15px",
              marginRight: "30px",
            }}
            className="customShadowCss"
          >
            <Card.Body>
              <Card.Title className="metricsSmallCardsCss">
                {"Total Reports"}
              </Card.Title>

              <Card.Subtitle>
                {numberOfReportsInMonth ? numberOfReportsInMonth : "0"}
              </Card.Subtitle>
            </Card.Body>
          </Card>
        </div>
        <div>
          <Card style={{ borderRadius: "15px" }} className="customShadowCss">
            <Card.Body>
              <Card.Title className="metricsSmallCardsCss">
                {"Day Average"}
              </Card.Title>

              <Card.Subtitle>{averageToDisplay}</Card.Subtitle>
            </Card.Body>
          </Card>
        </div>
      </div> */}

      {/* Modal Start*/}

      <Dialog
        fullWidth
        maxWidth="xs"
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select Custom Date</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Box>
              <Typography mb={1}>From Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={selectedFromDate}
                onChange={(e) => {
                  setSelectedFromDate(e.target.value);
                  setEpochFromTime(formatFromDate(e.target.value));
                  setMaxDateCalender(e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography mb={1}>To Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={selectedToDate}
                max={maxcalenderDate}
                onChange={(e) => {
                  setSelectedToDate(e.target.value);
                  setEpochToTime(formatToDate(e.target.value));
                }}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button
            variant="contained"
            size="small"
            onClick={async () => {
              await customDatesDayWiseCounts();
            }}
          >
            Save Changes
          </Button>
          <Button variant="contained" size="small" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal End */}

      <Card style={{ borderRadius: "8px", border: "none" }}>
        <Card.Body style={{ padding: "24px" }}>
          <Card.Title className="metricsButtonsAlignCss mb-3">
            <div>
              <Typography variant="h2">
                {content.monthlyReport.title}
              </Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }} my={2}>
                {content.monthlyReport.subHeading}
              </Typography>
              <Spinner
                style={{
                  marginLeft: "5px",
                  display: loadingGraphValuesStatus ? "" : "none",
                }}
                id="loadingSpinnerCss"
                animation="border"
                role="status"
                size="sm"
              />
            </div>
            <div className="metricsButtonMarginsCss">
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
                size="small"
                variant="filled"
              >
                <InputLabel id="demo-select-small-label">
                  Time Period
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={selectedMetric}
                  label="Count"
                  onChange={handleSelection}
                >
                  <MenuItem value="currentWeek">Current Week</MenuItem>
                  <MenuItem value="currentMonth">Current Month</MenuItem>
                  <MenuItem value="previousMonth">Previous Month</MenuItem>
                  <MenuItem value="customMonth">Custom Month</MenuItem>
                  {/* <MenuItem value="yearly">Yearly</MenuItem> */}
                </Select>
              </FormControl>
              {/* <button
                //style={{ color: !pieDataCurrentMonth ? "#fff" : "", backgroundColor: !pieDataCurrentMonth ? "#3567D6" : "" }}
                onClick={async () => {
                  setCurrentMonthSelection(true);
                  setCustomMonthSelection(false);
                  !currentMonthSelection && (await currentMonthDayWiseCounts());
                }}
                className="metricsChartButtonCss"
                style={{
                  color: currentMonthSelection ? "#fff" : "",
                  backgroundColor: currentMonthSelection ? "#3567D6" : "",
                }}
              >
                <span className="currentMonthTextCss"></span>
              </button>
              <button
                //style={{ color: !pieDataCurrentMonth ? "#fff" : "", backgroundColor: !pieDataCurrentMonth ? "#3567D6" : "" }}
                onClick={async () => {
                  setCurrentMonthSelection(false);
                  setCustomMonthSelection(false);
                  await previousMonthDayWiseCounts();
                }}
                className="metricsChartButtonCss"
                style={{
                  color:
                    !currentMonthSelection && !customMonthSelection
                      ? "#fff"
                      : "",
                  backgroundColor:
                    !currentMonthSelection && !customMonthSelection
                      ? "#3567D6"
                      : "",
                }}
              >
                <span className="previousMonthTextCss"></span>
              </button>
              <button
                //style={{ color: pieDataCurrentMonth ? "#fff" : "", backgroundColor: pieDataCurrentMonth ? "#3567D6" : "" }}
                onClick={() => {
                  handleShow(true);
                  setCurrentMonthSelection(false);
                  setCurrentMonthSelection(false);
                }}
                className="metricsChartButtonCss"
                style={{
                  color: customMonthSelection === true ? "#fff" : "",
                  backgroundColor:
                    customMonthSelection === true ? "#3567D6" : "",
                }}
              >
                <span className="customMonthTextCss"></span>
              </button> */}
              {/* <div
                onClick={downloadMetricsHandler}
                title={titleText}
                style={{
                  cursor: "pointer",
                  display:
                    loginData &&
                    loginData.role !== "LIS" &&
                    loginData.role !== "admin"
                      ? "inline"
                      : "none",
                }}
              >
                <span style={{ display: downloadStatus ? "none" : "" }}>
                  <DownloadSvgSmall />
                </span>
                <span
                  style={{
                    position: "relative",
                    display: downloadStatus ? "" : "none",
                  }}
                >
                  <Spinner animation="border" role="status" size="sm" />
                </span>
              </div> */}

              {/* <a
                                href="https://clientdashboard.s3.ap-south-1.amazonaws.com/reports/undefined/undefined/2022/May/undefined_May_data.xlsx"
                                download
                            >Download</a> 
                            */}
            </div>
          </Card.Title>
          <Card.Subtitle>
            {selectedMetric !== "yearly" ? (
              <div>
                {reportCount.length === 0 && !loader ? (
                  <span style={{ color: "#7787bc" }}>
                    {"No Data to display"}
                  </span>
                ) : loader ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <Stack gap={2}>
                    {/* <div style={{ display: "inline-flex" }} className="mb-4">
                      <div>
                        <Card
                          style={{
                            borderRadius: "15px",
                            marginRight: "30px",
                          }}
                          className="customShadowCss"
                        >
                          <Card.Body>
                            <Card.Title className="metricsSmallCardsCss">
                              {"Total Reports"}
                            </Card.Title>

                            <Card.Subtitle>
                              {numberOfReportsInMonth
                                ? numberOfReportsInMonth.toLocaleString("en-IN")
                                : "0"}
                            </Card.Subtitle>
                          </Card.Body>
                        </Card>
                      </div>
                      <div>
                        <Card
                          style={{ borderRadius: "15px" }}
                          className="customShadowCss"
                        >
                          <Card.Body>
                            <Card.Title className="metricsSmallCardsCss">
                              {"Day Average"}
                            </Card.Title>

                            <Card.Subtitle>
                              {averageToDisplay.toLocaleString("en-IN")}
                            </Card.Subtitle>
                          </Card.Body>
                        </Card>
                      </div>
                    </div> */}
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      flexWrap="wrap"
                      gap={2}
                    >
                      <Stack direction="row" gap={4.5}>
                        <Stack
                          sx={{
                            borderRadius: "16px",
                            border: "1px solid #E8E7E7",
                          }}
                        >
                          <Stack
                            direction="row"
                            gap={3}
                            sx={{ padding: "16px 16px 0px 16px" }}
                          >
                            <Stack
                              justifyContent="center"
                              alignItems="center"
                              gap={3}
                              sx={{
                                width: "58px",
                                height: "58px",
                                borderRadius: "6px",
                                background: "#F8DEBD",
                              }}
                            >
                              <AssignmentIcon sx={{ color: "#BA8139" }} />
                            </Stack>
                            <Box>
                              <Typography variant="h6">
                                Total Reports
                              </Typography>
                              <Typography variant="h4">
                                {numberOfReportsInMonth
                                  ? numberOfReportsInMonth.toLocaleString(
                                      "en-IN"
                                    )
                                  : "0"}
                              </Typography>
                            </Box>
                          </Stack>
                          <img
                            src="https://niroggyan.s3.ap-south-1.amazonaws.com/new-billing-dashboard-files/logos/report_count_trend1.png"
                            alt="trend"
                          />
                        </Stack>
                        <Stack
                          sx={{
                            borderRadius: "16px",
                            border: "1px solid #E8E7E7",
                            width: "260px",
                          }}
                        >
                          <Stack
                            direction="row"
                            gap={3}
                            sx={{ padding: "16px 16px 0px 16px" }}
                          >
                            <Stack
                              justifyContent="center"
                              alignItems="center"
                              gap={3}
                              sx={{
                                width: "58px",
                                height: "58px",
                                borderRadius: "6px",
                                background: "#FBF0F3",
                              }}
                            >
                              <img
                                src="https://niroggyan.s3.ap-south-1.amazonaws.com/new-billing-dashboard-files/logos/day_avg_icon.png"
                                alt="day_avg"
                              />
                            </Stack>
                            <Box>
                              <Typography variant="h6">Day Average</Typography>
                              <Stack
                                direction="row"
                                gap={1}
                                alignItems="center"
                              >
                                <Typography variant="h4">
                                  {averageToDisplay.toLocaleString("en-IN")}
                                </Typography>
                                {averageToDisplay &&
                                prevMonthData?.length &&
                                selectedMetric === "currentMonth"
                                  ? showVariation()
                                  : ""}
                              </Stack>
                            </Box>
                          </Stack>
                          <img
                            src="https://niroggyan.s3.ap-south-1.amazonaws.com/new-billing-dashboard-files/logos/report_count_trend2.png"
                            alt="trend"
                          />
                        </Stack>
                      </Stack>
                      <Stack gap={1.5} pr={1}>
                        <Button
                          onClick={downloadMetricsHandler}
                          // sx={{
                          //   textTransform: "none",
                          //   background:
                          //     "linear-gradient(to left, #22D1EE, #3D5AF1)",
                          //   padding: "12px 20px",
                          //   borderRadius: "30px",
                          // }}
                        >
                          {downloadStatus ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100px",
                              }}
                            >
                              <CircularProgress
                                size={20}
                                sx={{ color: "#fff" }}
                              />
                            </Box>
                          ) : (
                            <>
                              Download{" "}
                              <span>
                                <DownloadIcon />
                              </span>
                            </>
                          )}
                        </Button>
                        <Typography variant="body1">
                          Download full report
                        </Typography>
                      </Stack>
                    </Stack>
                    <Chart
                      options={dataToRender.options}
                      series={dataToRender.series}
                      type="bar"
                      width={"100%"}
                      height={320}
                    />
                  </Stack>
                )}
              </div>
            ) : (
              // <YearWiseMetrics productType={productType} />
              ""
            )}
          </Card.Subtitle>
          {/* {selectedMetric !== "yearly" ? (
            <div className="columnBarFooterItemsCss">
              <Card.Link
                className="dayWiseFooterCss"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <CalenderSvg />
                {"Day wise Report Count"}
              </Card.Link>
              <Card.Link
                className="reportsGeneratedFooterCss"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ReportsGeneratedSvgIcon />
                {"Reports Generated"}
              </Card.Link>
            </div>
          ) : (
            ""
          )} */}
        </Card.Body>
      </Card>
    </div>
  );
};

export default MetricsSection;
