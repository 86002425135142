import { Label } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import Chart from "react-apexcharts";
import { useEffect, useMemo, useState } from "react";
import Legend from "./Legend";
import {
  CustomBox,
  CustomCountBox,
  CustomGraphBox,
  CustomShadowBox,
} from "../../common/BoxAbstraction";
import CountContainer from "../../common/CountContainer";
import LoadingView from "../../common/LoadingView";
import ErrorView from "../../common/ErrorView";
import EmptyView from "../../common/EmptyView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CollapsableHeader from "../../common/CollapsableHeader";
import { content, getCurrentWeekDates } from "../utils";
import WIPSection from "../../common/WIPSection";

const AbnormalParameters = () => {
  const today = new Date().getDate();
  const [selectedMetric, setSelectedMetric] = useState("currentWeek");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [maxcalenderDate, SetMaxCalenderDate] = useState("");
  const [parameterList, setParameterList] = useState("");
  const [selectedParameter, setSelectedParameter] = useState("");
  const [error, setError] = useState("");
  const [ageGenderwiseParameterData, setAgeGenderwiseParameterData] =
    useState("");
  const [parameterListLoader, setParameterListLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleShow = () => setOpen(true);
  const loginData = JSON.parse(localStorage.getItem("credentials"));

  const legendColors = ["#02c96a", "#fc6d5d"];
  const legendLabels = ["Normal", "Abnormal"];

  const getParamaterList = async () => {
    setParameterListLoader(true);
    const apiUrl = `https://api.niroggyan.com/analytics/smartreport/parameter/list?start=${startDate}&end=${endDate}&filter_by=date`;

    const idToken = localStorage.getItem("idTokenBilling");

    let options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: idToken,
      },
    };
    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("paramList", data);
      if (!data?.isSuccess) {
        setError("Failed to fetch data. Please try again.");
      } else {
        setError("");
        setParameterList(data.data);
        if (selectedMetric === "currentWeek") {
          localStorage.setItem("parameterList", JSON.stringify(data.data));
        }
      }
    } catch (e) {
      console.log("Error", e);
      setError("Failed to fetch data. Please try again.");
    } finally {
      setParameterListLoader(false);
    }
  };

  const fetchData = async () => {
    setLoader(true);
    const apiUrl = `https://api.niroggyan.com/analytics/smartreport/demographics/counts?query_on=age_gender&start=${startDate}&end=${endDate}&filter_by=parameter&parameter_name=${selectedParameter}`;
    const idToken = localStorage.getItem("idTokenBilling");

    let options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: idToken,
      },
    };
    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("genderwiseParameterdata", data);
      if (data?.data?.length === 0) {
        setLoader(false);
        setError("No data available.");
      } else {
        setLoader(false);
        setError("");
        setAgeGenderwiseParameterData(data?.data);
      }
    } catch (e) {
      setLoader(false);
      console.log("Error", e);
      setError("Failed to fetch data. Please try again.");
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      const storedParameterList = JSON.parse(
        localStorage.getItem("parameterList")
      );
      if (storedParameterList?.length) {
        setParameterList(storedParameterList);
      } else {
        getParamaterList();
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    // if (today < 5) {
    //   setStartDate(getPreviousMonthStartEndDates().startDate);
    //   setEndDate(getPreviousMonthStartEndDates().endDate);
    //   setSelectedMetric("previousMonth");
    // } else {
    //   setStartDate(getCurrentMonthStartEndDates().startDate);
    //   setEndDate(getCurrentMonthStartEndDates().endDate);
    //   setSelectedMetric("currentMonth");
    // }
    setStartDate(getCurrentWeekDates().startDate);
    setStartDate(getCurrentWeekDates().endDate);
  }, []);

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     fetchData();
  //   }
  // }, [startDate, endDate, selectedParameter]);

  const formatFromDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() >= 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 00:00:00.000`;
    console.log(requiredTime);
    return requiredTime;
  };

  const formatToDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() >= 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 23:59:59.999`;
    console.log(requiredTime);
    return requiredTime;
  };

  const getCurrentMonthStartEndDates = () => {
    let todaysDate = new Date();
    let startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      1,
      0,
      0,
      0,
      0
    );

    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(todaysDate),
    };
  };

  const getPreviousMonthStartEndDates = () => {
    let todaysDate = new Date();
    const startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth() - 1,
      1,
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      0,
      23,
      59,
      59,
      0
    );
    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(endDate),
    };
  };

  const setMaxDateCalender = (date) => {
    const dateFormat = new Date(date);
    const maxDate = new Date(dateFormat.setDate(dateFormat.getDate() + 31));
    const month =
      maxDate.getMonth() < 9
        ? `0${parseInt(maxDate.getMonth()) + 1}`
        : parseInt(maxDate.getMonth()) + 1;
    const day =
      maxDate.getDate() < 10 ? `0${maxDate.getDate()}` : maxDate.getDate();
    const formattedDate = `${maxDate.getFullYear()}-${month}-${day}`;

    SetMaxCalenderDate(formattedDate);
  };

  const customDateModal = () => {
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select Custom Date</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Box>
              <Typography mb={1}>From Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={startDate}
                onChange={(e) => {
                  setStartDate(formatFromDate(e.target.value));
                  setMaxDateCalender(e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography mb={1}>To Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={endDate}
                max={maxcalenderDate}
                onChange={(e) => setEndDate(formatToDate(e.target.value))}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button variant="contained" size="small" onClick={handleClose}>
            Save Changes
          </Button>
          <Button variant="contained" size="small" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleSelection = (e) => {
    setSelectedMetric(e.target.value);
    switch (e.target.value) {
      case "currentWeek":
        setStartDate(getCurrentWeekDates().startDate);
        setEndDate(getCurrentWeekDates().endDate);
        break;
      case "currentMonth":
        setStartDate(getCurrentMonthStartEndDates().startDate);
        setEndDate(getCurrentMonthStartEndDates().endDate);
        break;
      case "previousMonth":
        setStartDate(getPreviousMonthStartEndDates().startDate);
        setEndDate(getPreviousMonthStartEndDates().endDate);
        break;
      case "customMonth":
        setOpen(true);
        break;
    }
  };

  const getMaleFemaleData = () => {
    let totalMale = 0;
    let totalFemale = 0;
    ageGenderwiseParameterData.forEach((each) => {
      totalMale += each.Male ? parseInt(each.Male) : 0;
      totalFemale += each.Female ? parseInt(each.Female) : 0;
    });
    console.log("maleFemale", [totalMale, totalFemale]);
    return [totalMale, totalFemale];
  };

  const getAgeWiseGenderData = (gender) => {
    const genderWiseMale = [];
    const genderWiseFemale = [];
    ageGenderwiseParameterData.forEach((each) => {
      if (each.Male) {
        genderWiseMale.push(parseInt(each.Male));
      }
      if (each.Female) {
        genderWiseFemale.push(parseInt(each.Female));
      }
    });
    if (gender === "male") {
      return genderWiseMale;
    }
    return genderWiseFemale;
  };

  const getLabels = () => {
    const labelData = [];
    ageGenderwiseParameterData.forEach((each) => {
      labelData.push(each.age_group);
    });
    return labelData;
  };

  const selectedParameters = [
    "LDL : HDL ratio",
    "HbA1c (Glycosylated Haemoglobin)",
    "Vitamin D (25-Hydroxy)",
    "Vitamin B12",
    "TSH",
    "Creatinine",
    "Haemoglobin",
    "Albumin : Globulin ratio",
    "APO A : APO B Ratio",
    "Total Bilirubin",
    "ESR",
    "Iron",
    "Calcium",
    "Total Leukocyte Count",
    "CRP",
  ];

  const getColumnStackSeries = () => {
    const maleData = [];
    const femaleData = [];
    ageGenderwiseParameterData.map((each) => {
      if (each.Male) {
        maleData.push(parseInt(each.Male));
      }
      if (each.Female) {
        femaleData.push(parseInt(each.Female));
      }
    });
    const series = [
      { name: "Male", data: maleData },
      { name: "Female", data: femaleData },
    ];
    return series;
  };

  const getXaxisCategories = () => {
    const xAxisCategories = ageGenderwiseParameterData.map(
      (each) => Object.values(each)[0]
    );
    console.log("xAxisCategories", xAxisCategories);
    return [...new Set(xAxisCategories)];
  };

  const RenderChart = ({ isMale = false, isFemale = false }) => {
    const pieChartData = {
      options: {
        chart: {
          width: 400,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        // title: {
        //   text: "Age in Years",
        //   offsetY: 20,
        // },
        labels: isMale || isFemale ? getLabels() : ["Male", "Female"],
        plotOptions: {
          pie: {
            dataLabels: {
              offset: 0, // Set to 0 to align labels with the center of the slices
              minAngleToShowLabel: 10, // Set a minimum angle to show labels
            },
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                },
              },
            },
          },
        },
        colors:
          isMale || isFemale
            ? [
                "#f57740",
                "#f59827",
                "#16f284",
                "#f760b8",
                "#36ccd1",
                "#feb019",
                "#6e6b6a",
                "#d151e8",
                "#77f53d",
                "#0529f5",
              ]
            : ["#008ffb", "#ff6384"],
      },
      series:
        ageGenderwiseParameterData?.length && isMale
          ? getAgeWiseGenderData("male")
          : isFemale
          ? getAgeWiseGenderData("female")
          : getMaleFemaleData(),
    };
    return (
      <Stack>
        <Typography sx={{ textAlign: "center" }}>
          {isMale ? "Male" : isFemale ? "Female" : ""}
        </Typography>
        <Chart
          options={pieChartData.options}
          series={pieChartData.series}
          type="donut"
          width={400}
        />
      </Stack>
    );
  };

  const filterGenderWiseData = (gender) => {
    const genderWiseData =
      ageGenderwiseParameterData?.length &&
      ageGenderwiseParameterData.filter((each) => each.gender === gender);
    const chartData = { total_count: [], Normal: [], Abnormal: [] };
    genderWiseData.forEach((each) => {
      if (each.total_count) chartData["total_count"].push(each.total_count);
      if (each.normal_count) chartData["Normal"].push(each.normal_count);
      if (each.abnormal_count) chartData["Abnormal"].push(each.abnormal_count);
    });
    const dataSeries = [];
    for (let [key, value] of Object.entries(chartData)) {
      if (key !== "total_count") {
        dataSeries.push({ name: key, data: value });
      }
    }
    console.log("dataSeries", dataSeries);
    return dataSeries;
  };

  const genderWiseCounts = (gender) => {
    const genderWiseData =
      ageGenderwiseParameterData?.length &&
      ageGenderwiseParameterData.filter((each) => each.gender === gender);
    console.log("genderWiseData", genderWiseData);
    const chartData = { total_count: [], normal_count: [], abnormal_count: [] };
    genderWiseData.forEach((each) => {
      if (each.total_count)
        chartData["total_count"].push(parseInt(each.total_count));
      if (each.normal_count)
        chartData["normal_count"].push(parseInt(each.normal_count));
      if (each.abnormal_count)
        chartData["abnormal_count"].push(parseInt(each.abnormal_count));
    });
    return chartData;
  };

  const getSum = (data) => {
    if (data?.length) {
      return data.reduce((a, b) => a + b);
    }
  };

  const getCountData = (gender, label) => {
    if (ageGenderwiseParameterData?.length) {
      return getSum(genderWiseCounts(gender)[label]);
    }
  };

  const RenderGenderWiseChart = ({ gender }) => {
    const dataToRender = {
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          fontFamily: "IBM Plex Sans",
          // toolbar: {
          //   show: true,
          // },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            // columnWidth: "60%",
            dataLabels: {
              total: {
                enabled: true,
              },
            },
          },
        },
        xaxis: {
          categories:
            ageGenderwiseParameterData?.length && getXaxisCategories(),
          title: {
            text: "Age in years",
            margin: 10,
            style: {
              fontSize: "18px",
              fontWeight: 700,
            },
          },
          tickPlacement: "on",
        },
        legend: {
          show: false,
          position: "bottom",
          offsetY: 10,
        },
        yaxis: {
          title: {
            text: "Total Count",
            style: {
              fontSize: "18px",
              fontWeight: 700,
            },
          },
        },
        colors: ["#02c96a", "#fc6d5d"],
        // annotations: {
        //   yaxis: [
        //     {
        //       y: 500,
        //       borderColor: "#00E396",
        //       label: {
        //         borderColor: "#00E396",
        //         style: {
        //           color: "#fff",
        //           background: "#00E396",
        //         },
        //         text: "Avg Normal Count",
        //       },
        //     },
        //   ],
        // },
      },
      series:
        ageGenderwiseParameterData?.length && gender === "Male"
          ? filterGenderWiseData("Male")
          : filterGenderWiseData("Female"),
    };
    return (
      <Chart
        options={dataToRender.options}
        series={dataToRender.series}
        type="bar"
        width={"100%"}
        height={320}
      />
    );
  };

  const handleDataCall = () => {
    if (selectedParameter) {
      fetchData();
    } else {
      setError("Please choose relevant package & profile");
    }
  };

  const memoizedGraphFemale = useMemo(() => {
    return <RenderGenderWiseChart gender="Female" />;
  }, [ageGenderwiseParameterData]);

  const memoizedGraphMale = useMemo(() => {
    return <RenderGenderWiseChart gender="Male" />;
  }, [ageGenderwiseParameterData]);

  const totalCountMale = getCountData("Male", "total_count");
  const totalNormalCountMale = getCountData("Male", "normal_count");
  const totalAbnormalCountMale = getCountData("Male", "abnormal_count");
  const totalCountFemale = getCountData("Female", "total_count");
  const totalNormalCountFemale = getCountData("Female", "normal_count");
  const totalAbnormalCountFemale = getCountData("Female", "abnormal_count");
  const totalCount =
    (totalCountMale ? totalCountMale : 0) +
    (totalCountFemale ? totalCountFemale : 0);
  const totalNormal =
    (totalNormalCountMale ? totalNormalCountMale : 0) +
    (totalNormalCountFemale ? totalNormalCountFemale : 0);
  const totalAbnormal =
    (totalAbnormalCountMale ? totalAbnormalCountMale : 0) +
    (totalAbnormalCountFemale ? totalAbnormalCountFemale : 0);

  return (
    <Box flexGrow={1}>
      <Accordion component="Box">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {/* <Typography variant="h2" mb={2}>
            Essential Biomarkers
          </Typography> */}
          <CollapsableHeader
            title={content.essentialBiomarkers.title}
            subheading={content.essentialBiomarkers.subHeading}
            tooltipText={content.essentialBiomarkers.tooltipText}
          />
        </AccordionSummary>
        <AccordionDetails>
          <CustomBox>
            <CustomShadowBox sx={{ justifyContent: "flex-end" }}>
              {/* <Typography
          variant="body2"
          sx={{ maxWidth: { lg: "150px", md: "100%" } }}
        >
          Please choose relevant biomarker
        </Typography> */}
              <Stack direction="row" gap={1} flexWrap="wrap">
                <FormControl
                  size="small"
                  variant="filled"
                  sx={{ minWidth: "150px" }}
                >
                  <Autocomplete
                    disabled={loginData?.disableParameterData}
                    // disableClearable
                    value={selectedParameter}
                    sx={{ width: 250 }}
                    // options={selectedParameters?.length && selectedParameters}
                    options={parameterList?.length ? parameterList : []}
                    autoHighlight
                    getOptionLabel={(option) => option}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=" Choose Biomarker"
                        variant="filled"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off", // disable autocomplete and autofill
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setSelectedParameter(newValue);
                    }}
                  />
                </FormControl>
                {parameterListLoader && (
                  <Stack alignItems="center" justifyContent="center">
                    <CircularProgress size={20} />
                  </Stack>
                )}
                <FormControl
                  size="small"
                  variant="filled"
                  disabled={loginData?.disableParameterData}
                >
                  <InputLabel id="demo-select-small-label">
                    Time Period
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedMetric}
                    label="Date"
                    onChange={handleSelection}
                  >
                    <MenuItem value="currentWeek">Current Week</MenuItem>
                    <MenuItem value="currentMonth">Current Month</MenuItem>
                    <MenuItem value="previousMonth">Previous Month</MenuItem>
                    <MenuItem value="customMonth">Custom Month</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  onClick={handleDataCall}
                  disabled={
                    loginData?.disableParameterData
                      ? loginData?.disableParameterData
                      : loader
                  }
                >
                  Get Details
                </Button>
                {customDateModal()}
              </Stack>
            </CustomShadowBox>

            {/* {ageGenderwiseParameterData?.length ? (
        <Stack direction="row" gap={2} justifyContent="flex-end" mb={2} mt={2}>
          {legendColors.map((each, idx) => (
            <Legend key={idx} color={each} name={legendLabels[idx]} />
          ))}
        </Stack>
      ) : (
        ""
      )} */}

            <Stack>
              {loader ? (
                <LoadingView />
              ) : error ? (
                <ErrorView error={error} />
              ) : ageGenderwiseParameterData?.length ? (
                <Box>
                  <Stack my={2}>
                    <Stack direction="row" gap={2}>
                      <CustomCountBox sx={{ background: "#d1d1d1" }}>
                        <Typography variant="body9">
                          Total:{" "}
                          {totalCount ? totalCount.toLocaleString("en-IN") : 0}
                        </Typography>
                      </CustomCountBox>
                      <CustomCountBox sx={{ background: "#79fcbe" }}>
                        <Typography variant="body9">
                          Normal:{" "}
                          {totalNormal
                            ? totalNormal.toLocaleString("en-IN")
                            : 0}{" "}
                          {"("}
                          {totalCount
                            ? Math.round((totalNormal / totalCount) * 100)
                            : 0}
                          {"%)"}
                        </Typography>
                      </CustomCountBox>
                      <CustomCountBox sx={{ background: "#ffc8c2" }}>
                        <Typography variant="body9">
                          Abnormal:{" "}
                          {totalAbnormal
                            ? totalAbnormal.toLocaleString("en-IN")
                            : 0}
                          {"("}
                          {totalCount
                            ? Math.round((totalAbnormal / totalCount) * 100)
                            : 0}
                          {"%)"}
                        </Typography>
                      </CustomCountBox>
                    </Stack>
                  </Stack>
                  <Stack direction="column" gap={2}>
                    <CustomGraphBox>
                      <Stack
                        gap={2}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Stack alignSelf="stretch" flexGrow={2}>
                          <CountContainer
                            total={totalCountMale}
                            normal={totalNormalCountMale}
                            abnormal={totalAbnormalCountMale}
                          />
                        </Stack>
                        <Stack flexGrow={4}>
                          <Typography
                            variant="body10"
                            sx={{ textAlign: "center" }}
                          >
                            Male
                          </Typography>

                          <Stack
                            direction="row"
                            gap={2}
                            justifyContent="flex-end"
                            mb={2}
                            mt={2}
                          >
                            {legendColors.map((each, idx) => (
                              <Legend
                                key={idx}
                                color={each}
                                name={legendLabels[idx]}
                              />
                            ))}
                          </Stack>

                          {/* <RenderChart /> */}
                          <Box sx={{ width: "100%" }}>{memoizedGraphMale}</Box>
                        </Stack>
                      </Stack>
                    </CustomGraphBox>
                    <CustomGraphBox>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={2}
                      >
                        <Stack alignSelf="stretch" flexGrow={2}>
                          <CountContainer
                            total={totalCountFemale}
                            normal={totalNormalCountFemale}
                            abnormal={totalAbnormalCountFemale}
                          />
                        </Stack>
                        <Stack flexGrow={4}>
                          <Typography
                            variant="body10"
                            sx={{ textAlign: "center" }}
                          >
                            Female
                          </Typography>

                          <Stack
                            direction="row"
                            gap={2}
                            justifyContent="flex-end"
                            mb={2}
                            mt={2}
                          >
                            {legendColors.map((each, idx) => (
                              <Legend
                                key={idx}
                                color={each}
                                name={legendLabels[idx]}
                              />
                            ))}
                          </Stack>

                          <Box sx={{ width: "100%" }}>
                            {memoizedGraphFemale}
                          </Box>
                        </Stack>
                      </Stack>
                    </CustomGraphBox>
                  </Stack>
                </Box>
              ) : loginData?.displayName === "Dummy User" ? (
                <WIPSection />
              ) : (
                <EmptyView message="Please choose relevant biomarker" />
              )}
            </Stack>
          </CustomBox>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AbnormalParameters;
