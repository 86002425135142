import { Label } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useEffect, useState } from "react";
import { CustomBox, CustomShadowBox } from "../../common/BoxAbstraction";
import LoadingView from "../../common/LoadingView";
import ErrorView from "../../common/ErrorView";
import EmptyView from "../../common/EmptyView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CollapsableHeader from "../../common/CollapsableHeader";
import { content, getCurrentWeekDates } from "../utils";

const PackageWiseAnalysis = () => {
  const today = new Date().getDate();
  const [selectedMetric, setSelectedMetric] = useState("currentWeek");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [maxcalenderDate, SetMaxCalenderDate] = useState("");
  const [packageData, setPackageData] = useState("");
  const [profileData, setProfileData] = useState("");
  const [selectedPackage, setSelectedPackage] = useState("");
  const [selectedProfile, setSelectedProfile] = useState("");
  const [error, setError] = useState("");
  const [profileandPackageWiseData, setProfileandPackageWiseData] =
    useState("");
  const [loader, setLoader] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleShow = () => setOpen(true);
  const [packageLoader, setPackageLoader] = useState(false);
  const [profileListLoader, setProfileListLoader] = useState(false);

  const getPackageList = async () => {
    setPackageLoader(true);
    const apiUrl =
      "https://api.niroggyan.com/analytics/smartreport/package/list";

    const idToken = localStorage.getItem("idTokenBilling");

    let options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: idToken,
      },
    };
    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("setPackageData", data);
      if (data.isSuccess) {
        const filterData = data?.data.filter((each) => each != "");
        setPackageData(filterData);
        localStorage.setItem("packageList", JSON.stringify(filterData));
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setPackageLoader(false);
    }
  };

  const getProfileList = async () => {
    setProfileListLoader(true);
    const apiUrl = `https://api.niroggyan.com/analytics/smartreport/profile/list?filter_by=package&package_name=${selectedPackage}`;

    const idToken = localStorage.getItem("idTokenBilling");

    let options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: idToken,
      },
    };
    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("setPackageData", data);
      if (data.isSuccess) {
        setProfileData(data.data);
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setProfileListLoader(false);
    }
  };

  const fetchData = async () => {
    setLoader(true);
    setError("");
    const apiUrl = `https://api.niroggyan.com/analytics/smartreport/parameter/counts?filter_by=package_profile&profile_name=${selectedProfile}&package_name=${selectedPackage}&start=${startDate}&end=${endDate}`;
    const idToken = localStorage.getItem("idTokenBilling");

    let options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: idToken,
      },
    };
    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("setPackageDetails", data);

      if (data?.data?.length) {
        setLoader(false);
        setProfileandPackageWiseData(data?.data);
      } else {
        setLoader(false);
        setError("No data available for the chosen dates.");
      }
    } catch (e) {
      setLoader(false);
      console.log("Error", e);
      setError("Failed to fetch data. Please try again.");
    }
  };

  const downloadData = async () => {
    setIsDownloading(true);
    const apiUrl = `https://api.niroggyan.com/analytics/smartreport/parameter/data?filter_by=package_profile&profile_name=${selectedProfile}&package_name=${selectedPackage}&start=${startDate}&end=${endDate}`;
    const idToken = localStorage.getItem("idTokenBilling");

    let options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: idToken,
      },
    };
    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("setPackageData", data);
      if (data?.isSuccess && data?.data) {
        const fileUrl = response?.data?.data;
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "report.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("File download failed:", response?.data?.message);
      }
    } catch (e) {
      console.error("Error downloading data:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    // if (today < 5) {
    //   setStartDate(getPreviousMonthStartEndDates().startDate);
    //   setEndDate(getPreviousMonthStartEndDates().endDate);
    //   setSelectedMetric("previousMonth");
    // } else {
    //   setStartDate(getCurrentMonthStartEndDates().startDate);
    //   setEndDate(getCurrentMonthStartEndDates().endDate);
    //   setSelectedMetric("currentMonth");
    // }
    setStartDate(getCurrentWeekDates().startDate);
    setEndDate(getCurrentWeekDates().endDate);
    const storedPackageList = JSON.parse(localStorage.getItem("packageList"));
    if (storedPackageList?.length) {
      setPackageData(storedPackageList);
    } else {
      getPackageList();
    }
  }, []);

  useEffect(() => {
    if (selectedPackage) getProfileList();
  }, [selectedPackage]);

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     fetchData();
  //   }
  // }, [startDate, endDate, selectedPackage, selectedProfile]);

  const formatFromDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() >= 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 00:00:00.000`;
    console.log(requiredTime);
    return requiredTime;
  };

  const formatToDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() >= 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 23:59:59.999`;
    console.log(requiredTime);
    return requiredTime;
  };

  const getCurrentMonthStartEndDates = () => {
    let todaysDate = new Date();
    let startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      1,
      0,
      0,
      0,
      0
    );

    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(todaysDate),
    };
  };

  const getPreviousMonthStartEndDates = () => {
    let todaysDate = new Date();
    const startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth() - 1,
      1,
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      0,
      23,
      59,
      59,
      0
    );
    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(endDate),
    };
  };

  const setMaxDateCalender = (date) => {
    const dateFormat = new Date(date);
    const maxDate = new Date(dateFormat.setDate(dateFormat.getDate() + 31));
    const month =
      maxDate.getMonth() < 9
        ? `0${parseInt(maxDate.getMonth()) + 1}`
        : parseInt(maxDate.getMonth()) + 1;
    const day =
      maxDate.getDate() < 10 ? `0${maxDate.getDate()}` : maxDate.getDate();
    const formattedDate = `${maxDate.getFullYear()}-${month}-${day}`;

    SetMaxCalenderDate(formattedDate);
  };

  const customDateModal = () => {
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select Custom Date</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Box>
              <Typography mb={1}>From Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={startDate}
                onChange={(e) => {
                  setStartDate(formatFromDate(e.target.value));
                  setMaxDateCalender(e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography mb={1}>To Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={endDate}
                max={maxcalenderDate}
                onChange={(e) => setEndDate(formatToDate(e.target.value))}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button variant="contained" size="small" onClick={handleClose}>
            Save Changes
          </Button>
          <Button variant="contained" size="small" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleSelection = (e) => {
    setSelectedMetric(e.target.value);
    switch (e.target.value) {
      case "currentWeek":
        setStartDate(getCurrentWeekDates().startDate);
        setEndDate(getCurrentWeekDates().endDate);
        break;
      case "currentMonth":
        setStartDate(getCurrentMonthStartEndDates().startDate);
        setEndDate(getCurrentMonthStartEndDates().endDate);
        break;
      case "previousMonth":
        setStartDate(getPreviousMonthStartEndDates().startDate);
        setEndDate(getPreviousMonthStartEndDates().endDate);
        break;
      case "customMonth":
        setOpen(true);
        break;
    }
  };

  const handleDataCall = () => {
    if (packageData && profileData) {
      fetchData();
    } else {
      setError("Please choose relevant package & profile");
    }
  };

  return (
    <Box flexGrow={1}>
      <Accordion component="Box">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {/* <Typography variant="h2" mb={2}>
            Package Analysis
          </Typography> */}
          <CollapsableHeader
            title={content.packageAnalysis.title}
            subheading={content.packageAnalysis.subHeading}
            tooltipText={content.packageAnalysis.tooltipText}
          />
        </AccordionSummary>
        <AccordionDetails>
          <CustomBox>
            <CustomShadowBox mb={2}>
              <Stack direction="row" gap={2} flexWrap="wrap">
                <FormControl
                  size="small"
                  sx={{ minWidth: "200px", maxWidth: "250px" }}
                  variant="filled"
                  disabled={!packageData?.length}
                >
                  <InputLabel id="demo-select-small-label">
                    Choose Package
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedPackage}
                    label="Package"
                    onChange={(e) => setSelectedPackage(e.target.value)}
                  >
                    {packageData &&
                      packageData.map((packageName, idx) => (
                        <MenuItem key={idx} value={packageName}>
                          {packageName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {/* loader for package */}
                {packageLoader && (
                  <Stack alignItems="center" justifyContent="center">
                    <CircularProgress size={20} />
                  </Stack>
                )}
                <FormControl
                  size="small"
                  variant="filled"
                  sx={{ minWidth: "200px" }}
                  disabled={!selectedPackage?.length}
                >
                  <InputLabel id="demo-select-small-label">
                    Choose Profile
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedProfile}
                    label="Profile"
                    onChange={(e) => setSelectedProfile(e.target.value)}
                  >
                    {profileData &&
                      profileData.map((profile, idx) => (
                        <MenuItem key={idx} value={profile}>
                          {profile}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                {/* loader for profile */}
                {profileListLoader && (
                  <Stack alignItems="center" justifyContent="center">
                    <CircularProgress size={20} />
                  </Stack>
                )}
                <FormControl size="small" variant="filled">
                  <InputLabel id="demo-select-small-label">
                    Time Period
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedMetric}
                    label="Date"
                    onChange={handleSelection}
                  >
                    <MenuItem value="currentWeek">Current Week</MenuItem>
                    <MenuItem value="currentMonth">Current Month</MenuItem>
                    <MenuItem value="previousMonth">Previous Month</MenuItem>
                    <MenuItem value="customMonth">Custom Month</MenuItem>
                  </Select>
                </FormControl>
                {/* {profileandPackageWiseData?.length > 0 && (
          <IconButton
            onClick={() => {
              downloadData();
            }}
            disabled={isDownloading}
          >
            {isDownloading ? <CircularProgress size={24} /> : <DownloadIcon />}
          </IconButton>
        )} */}
                <Button onClick={handleDataCall} disabled={loader}>
                  Get Details
                </Button>
                {customDateModal()}
              </Stack>
            </CustomShadowBox>

            <Stack>
              {loader ? (
                <LoadingView />
              ) : error ? (
                <ErrorView error={error} />
              ) : profileandPackageWiseData?.length ? (
                <TableContainer
                  component={Paper}
                  sx={{ maxHeight: "350px", overflowY: "auto" }}
                >
                  <Table
                    sx={{ minWidth: "100%" }}
                    aria-label="simple table"
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "600" }}>
                          Parameter Name
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: "600" }}>
                          Total Count
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: "600" }}>
                          Abnormality Count
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: "600" }}>
                          % Abnormality
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {profileandPackageWiseData.map((each, idx) => (
                        <TableRow
                          key={idx}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">
                            {each.parameter_name}
                          </TableCell>
                          <TableCell align="center">
                            {each.total_count?.toLocaleString("en-IN")}
                          </TableCell>
                          <TableCell align="center">
                            {each.abnormal_count?.toLocaleString("en-IN")}
                          </TableCell>

                          <TableCell align="center">
                            {Math.round(
                              (each?.abnormal_count / each?.total_count) * 100
                            ) + "%"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <EmptyView message="Please choose relevant package & profile" />
              )}
            </Stack>
          </CustomBox>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default PackageWiseAnalysis;
