import { styled } from "@mui/material/styles";
import Box from "@mui/system/Box";

export const CustomBox = styled(Box)(() => ({
  background: "#fff",
  borderRadius: "8px",
  padding: "50px 60px",
  marginTop: "16px",
  border: "1px solid #D7D7D7",
  boxShadow: "0px 1px 3px 0px rgba(63,61,60,0.15)",
}));

export const CustomShadowBox = styled(Box)(() => ({
  borderRadius: "12px",
  padding: "16px 20px",
  border: "1px solid #E8E7E7",
  boxShadow: "0px 1px 50px rgba(0,0,0,0.08)",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  width: "100%",
}));

export const CustomGraphBox = styled(Box)(() => ({
  borderRadius: "6px",
  padding: "24px 18px",
  border: "1px solid #E8E7E7",
  boxShadow: "0px 1px 50px rgba(0,0,0,0.08)",
}));

export const CustomCountBox = styled(Box)(() => ({
  borderRadius: "8px",
  padding: "12px",
  background: "#D0FBFF",
}));
